import { observer } from 'mobx-react';

import { TabsComponentRenderProps } from './types';

import { NOTES_TAB_VALUE } from '../../data';

export const TabsComponentRender = observer(({
  components,
  instanceStore,
  renderNotes
}: TabsComponentRenderProps) => {
  const { currentTab } = instanceStore;

  if(currentTab === NOTES_TAB_VALUE){
    return renderNotes();
  }

  const componentRender = components[currentTab];

  return componentRender ? componentRender() : null;
});


