import { isEmpty } from 'lodash';
import { parse } from 'date-fns';

import { CONTACT_TYPES } from '@constants/common';
import { HOURS_MINUTES_SECONDS, YEAR_MONTH_DAY } from '@constants/dateFormats';
import { NOTES_LINKED_ITEMS_IDS, NOTES_LINKED_ITEMS_TYPES_SCHEME } from '@constants/overview';

import { ContactActivity } from '@services/store/contactDetailsActivitiesStore/types';
import { ContactNote, ContactNotesPayload } from '@/shared/types/note';
import { IdType } from '@/shared/types/commonTypes';
import {
  OverviewContact,
  RelatedContact
} from '@/shared/types/contactOverview';
import { HouseholderContactItem } from '@/shared/types/contact';

export const spouseNormalizer = (contact: OverviewContact): RelatedContact | null => {
  if(!contact) {
    return null;
  }
  if(contact.type === CONTACT_TYPES.BUSINESS) {
    if(contact.contactEmployeeData) {
      return {
        firstName: contact.contactEmployeeData.firstName,
        lastName: contact.contactEmployeeData.lastName,
        id: contact.contactEmployeeData.id,
        editAllow: contact.contactEmployeeData.editAllow,
        photo: contact.contactEmployeeData.photo,
        relationType: contact.contactEmployeeData.title,
      };
    }
  }

  if(contact.type === CONTACT_TYPES.HOUSEHOLD) {
    const spouse = contact.householderContacts.filter((contact) => contact.householdOwner === 'No')[0];
    return {
      firstName: spouse.firstName,
      lastName: spouse.lastName,
      id: spouse.id,
      editAllow: spouse.editAllow,
      photo: spouse.photo,
      relationType: 'Spouse',
    };
  }

  if(contact.type === CONTACT_TYPES.INDIVIDUAL) {
    const { householderContacts, relatedContact } = contact;
    if(householderContacts.length > 0) {
      const spouse = contact.householderContacts.filter((household) => (
        household.id !== contact.id)
      )[0];
      return spouse ? {
        firstName: spouse.firstName,
        lastName: spouse.lastName,
        id: spouse.id,
        editAllow: spouse.editAllow,
        photo: spouse.photo,
        relationType: 'Spouse',
      } : null;
    }
    if(relatedContact.length > 0) {
      const spouse = contact.relatedContact.filter((contact) => contact.relationType === 'Spouse')[0];
      return spouse ? {
        firstName: spouse.firstName,
        lastName: spouse.lastName,
        id: spouse.relativeId!,
        editAllow: spouse.editAllow,
        photo: spouse.photo,
        relationType: spouse.relationType,
      } : null;
    }
  }

  return null;
};

export const contactNormalizer = (data: OverviewContact): OverviewContact | null => {
  if(!data) {
    return null;
  }

  if(data.type === CONTACT_TYPES.HOUSEHOLD) {
    const headOfHousehold = data.householderContacts.filter((contact: HouseholderContactItem) => (
      contact.householdOwner === 'Yes')
    )[0];
    return {
      ...data,
      firstName: headOfHousehold.firstName,
      lastName: headOfHousehold.lastName,
      birthdate: headOfHousehold.birthdate,
      occupations: headOfHousehold.occupations,
      rating: headOfHousehold.rating,
      photo: headOfHousehold.photo,
      contacts: headOfHousehold.contacts,
    };
  }

  return data;
};

export const getParamsToSaveContactNote = (id: IdType, note: ContactNote): ContactNote => {
  const { linkedItem, linkedItemId } = note;

  Object.values(NOTES_LINKED_ITEMS_IDS).forEach((id) => {
    note[id] = null;
  });

  if(linkedItem && linkedItem.linkedContactType && linkedItemId) {
    Object.values(NOTES_LINKED_ITEMS_TYPES_SCHEME).forEach((linkedItemData) => {
      if(linkedItemData.type === linkedItem.linkedContactType) {
        note[linkedItemData.id] = linkedItemId;
      }
    });
  }

  delete note.linkedItemId;
  delete note.linkedItem;

  note.id = note.id || null;
  note.noteContactId = note.noteContactId ?? id;
  return note;
};


export const normalizerActivityData = (data: Array<ContactActivity>): Array<ContactActivity> => {
  if(isEmpty(data)) {
    return [];
  }
  const activityData = data.sort((activity, nextActivity) => {
    const activityDate = parseActivityStartDate(activity.startDate, activity.startTime);
    const nextActivityDate = parseActivityStartDate(nextActivity.startDate, nextActivity.startTime);
    return activityDate - nextActivityDate;
  });

  return activityData;
};

const parseActivityStartDate = (startDate: string, startTime: string) => {
  return parse(`${startDate} ${startTime}`, `${YEAR_MONTH_DAY} ${HOURS_MINUTES_SECONDS}`, new Date()).getTime();
};