import { CONTACT_TYPES, CORE_CLASSIFICATIONS, UserStatuses } from '@constants/common';
import { AddSpouseData } from '@services/store/contactDetailsProfileStore/types/common';
import { IdType } from '@/shared/types/commonTypes';

import { ExistedContactType, FieldsType } from './types';
import {
  ACTION_TYPES,
  ADD_SPOUSE_FIELD_NAMES,
  ADD_SPOUSE_TYPES,
  NEW_CONTACT_HEAD_OF_HOUSEHOLD_VALUE
} from './data';

export const convertData = (
  data:FieldsType,
  existedContactType: ExistedContactType | null,
  currentOfficePrimaryAdvisor: IdType
):AddSpouseData => {
  const householdData = data.spouseType === ADD_SPOUSE_TYPES.combineHousehold
    ? {
      householdData: {
        id: data.headOfHouseHoldId === NEW_CONTACT_HEAD_OF_HOUSEHOLD_VALUE
          ? ''
          : data.headOfHouseHoldId
      }
    }
    : { householdData: {} };

  if(data.addType === ACTION_TYPES.existed && existedContactType){
    return {
      ...householdData,
      spouseData: {
        officePrimaryAdvisor: '808',
        type: existedContactType.type,
        status: UserStatuses.Active,
        classification: existedContactType.classification,
        id: existedContactType.id,
        firstName: existedContactType.firstName,
        lastName: existedContactType.lastName
      }
    };
  }

  if(data.addType === ACTION_TYPES.new){
    const commonData = {
      [ADD_SPOUSE_FIELD_NAMES.firstName]: data.firstName,
      [ADD_SPOUSE_FIELD_NAMES.lastName]: data.lastName,
      ...(data?.middleName && { [ADD_SPOUSE_FIELD_NAMES.middleName]: data.middleName }),
      ...(data?.nickname && { [ADD_SPOUSE_FIELD_NAMES.nickname]: data.nickname }),
      ...(data?.birthdate && { [ADD_SPOUSE_FIELD_NAMES.birthdate]: data.birthdate }),
      ...(data?.gender && { [ADD_SPOUSE_FIELD_NAMES.gender]: data.gender }),
    };

    if(data.spouseType === ADD_SPOUSE_TYPES.combineHousehold){
      return {
        ...householdData,
        spouseData: {
          ...commonData,
          status: UserStatuses.Active,
          classification: CORE_CLASSIFICATIONS.CLIENT,
          type: CONTACT_TYPES.INDIVIDUAL,
          officePrimaryAdvisor: currentOfficePrimaryAdvisor,
          id: '',
        }
      };
    }

    if(data.spouseType === ADD_SPOUSE_TYPES.linkedSeparateRecord){
      return {
        ...householdData,
        spouseData: {
          ...commonData,
          type: CONTACT_TYPES.INDIVIDUAL,
          id: '',
          classification: data.classification,
          status: data.status,
          officePrimaryAdvisor: data.officePrimaryAdvisor,
          ...(data?.rating && { [ADD_SPOUSE_FIELD_NAMES.rating]: data.rating }),
          ...(data?.sourceId && { [ADD_SPOUSE_FIELD_NAMES.sourceId]: data.sourceId }),
          ...(data?.sourceCategory && { [ADD_SPOUSE_FIELD_NAMES.sourceCategory]: data.sourceCategory }),
        }
      };
    }
  }

  return {} as AddSpouseData;
};
