import { Store } from '@store';
import { action, flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';

import CommonTableStore from '@services/store/commonTableStore';

import { UseFormReset } from 'react-hook-form';
import { CloseModal } from '@/shared/types/commonTypes';
import {
  ConvertedGridAndKanbanFilterDataType,
  GridAndKanbanFiltersState,
  SalesActiveCycleGridItem,
  SalesCycleDeleteData,
  SalesCyclePartialUpdateData,
  SalesCycleRowFormState,
  SalesCycleStageFilter,
  SalesGridBackendResponse,
  SaveSalesActiveCyclePipelineData
} from '@/shared/types/salesActiveCycle';
import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';

import { CommonStoreForKanbanAndTable } from '../../commonStoreForKanbanAndTable';
import { CommonStore } from '../../commonStore';
import {
  deleteSalesCycle,
  getSaleActiveCycleTable,
  partialUpdateSalesCycle,
  saveSalesActiveCyclePipeline,
  updateSalesCycleFlags
} from '@services/api/salesPipeline/salesActiveCycle';

import {
  getFilterParams,
  getFiltersCount,
  getGlobalFlagged,
  getMultipleSortParams,
  setWhereNameAndOrderNameFilterParams
} from '@/shared/utils/filterUtils';
import { convertFilterData } from '../../utils';
import { filterItemsByPermission } from '@/shared/utils/filterItemsByPermission';

import { INIT_FILTERS_DATA, INIT_FILTERS_STATE } from '../../data';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES, SALES_ACTIVE_CYCLE_FILTER_NAMES } from '@constants/salesActiveCycle';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';


export class SalesActiveCycleTable implements CommonStore, CommonStoreForKanbanAndTable{
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  filterData: ConvertedGridAndKanbanFilterDataType = INIT_FILTERS_DATA;
  filters: GridAndKanbanFiltersState = INIT_FILTERS_STATE;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onFiltersChangeReaction: IReactionDisposer;
  onTypeFilterReaction: IReactionDisposer;
  stageFilter: SalesCycleStageFilter = null;
  table: CommonTableStore<SalesActiveCycleGridItem>;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getSalesCycles: flow.bound,
      getSalesCyclesWithLoad: flow.bound,
      init: flow.bound,
      onRemove: flow.bound,
      onRowChange: flow.bound,
      onSave: flow.bound,
      resetFilters: action.bound,
      updateFlagsMassive: flow.bound,
      updateFlagsSingle: flow.bound,
      resetStore: action.bound
    });
    this.coreStore = coreStore;

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.salesCycle
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.table = new CommonTableStore<SalesActiveCycleGridItem>({
      checkboxItemsProcessor: filterItemsByPermission,
      onGlobalFlaggedChangeReactionCallback: this.getSalesCyclesWithLoad,
      onSortReactionCallback: this.getSalesCyclesWithLoad,
      onPageChangeReactionCallback:  this.getSalesCyclesWithLoad
    });

    this.onTypeFilterReaction = this.createOnTypeFilterReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  *getSalesCycles(){
    if(!this.isPageActive){
      return;
    }

    try {
      this.table.clearItems(true);

      const start = async () => {

        let response:SalesGridBackendResponse = await getSaleActiveCycleTable({
          page: this.table.currentPage,
          ...getFilterParams(this.filters),
          ...getFilterParams(this.stageFilter),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
        });

        this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.salesCycle], {
          page: this.table.currentPage,
          ...setWhereNameAndOrderNameFilterParams({
            whereFilters: {
              ...this.filters,
              [SALES_ACTIVE_CYCLE_FIELD_NAMES.stage]: [this.stageFilter?.stage],
              flagged: [Number(this.table.globalFlagged)]
            },
            orderFilters: this.table.multipleSorting
          }),
        });

        const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
        this.filterData = convertFilterData(response.data.data.data.filterData, currentUserId);

        this.table.items = convertObjectWithNumericKeysToArray<SalesActiveCycleGridItem>(response.data.data.data);
        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: Math.random() * 10000
        }),
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getSalesCyclesWithLoad(){
    if(!this.isPageActive){
      return;
    }
    try {
      this.coreStore.SalesCycleStore.isLoad = true;
      yield this.getSalesCycles();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
    }
  }

  *init() {
    this.setFiltersFromServer();

    this.isPageActive = true;
    yield this.getSalesCyclesWithLoad();
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.salesCycle]
    ))?.value;

    this.onFiltersChangeReaction();
    this.onTypeFilterReaction();
    this.table.onPageChangeReaction();
    this.table.onGlobalFlaggedChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);

      if(serverFilterValue.filters?.where) {
        const {
          flagged,
          meetingStatus,
          officePrimaryAdvisor,
          salesCycleStatus,
          stage,
        } = serverFilterValue.filters.where;

        this.filters = {
          [SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor]: officePrimaryAdvisor,
          [SALES_ACTIVE_CYCLE_FILTER_NAMES.salesCycleStatus]: salesCycleStatus,
          [SALES_ACTIVE_CYCLE_FILTER_NAMES.meetingStatus]: meetingStatus,
          [SALES_ACTIVE_CYCLE_FILTER_NAMES.name]: '',
          [SALES_ACTIVE_CYCLE_FILTER_NAMES.stage]: []
        };
        this.stageFilter = {
          [SALES_ACTIVE_CYCLE_FIELD_NAMES.stage]: stage?.[0]
        };
        this.table.setGlobalFlaggedFilters(Boolean(flagged?.[0]) || false);
      }
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onGlobalFlaggedChangeReaction = this.table.createOnGlobalFlaggedChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onTypeFilterReaction = this.createOnTypeFilterReaction();
  }

  *onRemove(data: SalesCycleDeleteData) {
    try {
      this.coreStore.SalesCycleStore.isLoad = true;
      const idsToDelete = Array.isArray(data) ? data : [data];

      const countOfEntities = idsToDelete.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => deleteSalesCycle({
          ids: idsToDelete
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
          isAutoUniqueKey: true
        })
      });

      yield this.getSalesCycles();

      yield this.table.checkAndSetIfPageOutOfRange();
      yield this.table.refreshSelectedIds(idsToDelete);
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }
  *onRowChange(data: SalesCyclePartialUpdateData, rowReset: UseFormReset<SalesCycleRowFormState>) {
    const isSortedOrFiltered = this.filtersCount > 0 ||
      Object.keys(this.table.multipleSorting).length > 0 ||
      this.stageFilter;
    const { item: itemPrevState } = this.table.getItemAndItemIndex(data.id);

    const wrappedUpdate = () => (
      this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => partialUpdateSalesCycle(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isUpdate: true,
          isError: true,
          isAutoUniqueKey: true
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isUpdate: true,
          isError: false,
          isAutoUniqueKey: true
        })
      })
    );

    try {
      if(!isSortedOrFiltered){
        rowReset(data);
        this.table.updateItemById(data.id, data);
        yield wrappedUpdate();
      } else {
        this.coreStore.SalesCycleStore.isLoad = true;
        yield wrappedUpdate();

        yield this.getSalesCycles();
      }
    } catch (error) {
      if(!isSortedOrFiltered  && itemPrevState){
        rowReset({
          [SALES_ACTIVE_CYCLE_FIELD_NAMES.meetingStatus]: itemPrevState.meetingStatus,
          [SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus]: itemPrevState.salesCycleStatus,
        });
        this.table.updateItemById(itemPrevState.id, itemPrevState);
      }
      console.log(error);
    } finally {
      if(isSortedOrFiltered){
        this.coreStore.SalesCycleStore.isLoad = false;
      }

      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *onSave(closeModal: CloseModal, data: SaveSalesActiveCyclePipelineData) {
    try {
      closeModal();
      this.coreStore.SalesCycleStore.isLoad = true;

      const uniqueKey = data?.id || new Date().getTime();
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveSalesActiveCyclePipeline(data),
        onError: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: true,
          uniqueKey
        }),
        onSuccess: () => this.notificationHelper.createUpdateNotification({
          isUpdate: Boolean(data.id),
          isError: false,
          uniqueKey
        })
      });

      yield this.getSalesCycles();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.SalesCycleStore.isLoad = false;

      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFlagsMassive() {
    try {
      yield this.table.updateMassiveItemFlag(
        this.isAllItemsFlagged,
        (data) => updateSalesCycleFlags(data)
      );
    } catch (error) {
      console.log(error);
    }
  }

  *updateFlagsSingle(id: number) {
    try {
      yield this.table.updateSingleItemFlag(
        id,
        (data) => updateSalesCycleFlags([data])
      );
      if(this.table.globalFlagged) {
        this.getSalesCyclesWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive){
          this.table.selectedIDs = [];
          this.table.setCurrentPageWithoutReaction(1);
          this.getSalesCyclesWithLoad();
        }
      }, 1500),
    );
  }

  createOnTypeFilterReaction() {
    return reaction(
      () => this.stageFilter,
      () => {
        if (this.isPageActive) {
          this.table.selectedIDs = [];
          this.table.setCurrentPageWithoutReaction(1);
          this.getSalesCyclesWithLoad();
        }
      }
    );
  }

  get filtersCount () {
    return getFiltersCount(this.filters);
  }

  get isAllItemsFlagged ()  {
    return this.table.items.length > 0 && this.table.items.every(item => Boolean(item?.flagged));
  }

  resetFilters(){
    this.filters = INIT_FILTERS_STATE;
  }

  resetStore() {
    this.isPageActive = false;
    this.isFiltersOpen = false;

    this.table.resetTable();

    this.onFiltersChangeReaction();
    this.resetFilters();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  setFilterState = (name: string, value: Array<string> | string | null) =>{
    this.filters = {
      ...this.filters,
      [name]: value
    };
  };

  setStageFilter = (value: null | string) => {
    if(value) {
      this.stageFilter = {
        [SALES_ACTIVE_CYCLE_FIELD_NAMES.stage]: value
      };
    } else {
      this.stageFilter = null;
    }
  };

  toggleFiltersIsOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };
}

