import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import {
  FILTER_OPTIONS,
  FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES,
  FORM_NAMES,
  ROW_FILTER_ITEMS_NAME,
  SEARCH_BUTTON_MODE,
} from '@modules/NotesAndHistory/components/Notes/components/GeneralFilters/data';
import {
  INPUT_STYLE_PROPS,
  MENU_LIST_PROPS,
  SELECT_PROPS,
  TEXT_FIELD_PROPS
} from './stylesData';

import { Close } from '@/assets';
import BlockButton from '@components/BlockButton';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import HFTextInput from '@modules/HookFormComponents/HFTextInput';

import {
  CloseIcon,
  DatePickersWrapper,
  Divider,
  Wrapper,
  StyledPaper
} from './styles';

import { SearchPanelProps } from './types';


export const SearchPanel = ({
  control,
  isVisibleCloseIconButton,
  onChange,
  reset,
  setFilters,
  setIsVisibleCloseIconButton,
  setSearchIconButtonMode,
}: SearchPanelProps) => {

  const [ startDate, endDate, noteTitleContent, selectFilter ] = useWatch({
    control,
    name: [
      FORM_NAMES.startDate,
      FORM_NAMES.endDate,
      FORM_NAMES.noteTitleContent,
      FORM_NAMES.selectFilter
    ]
  });

  const onSearchButtonClick = useCallback(() => {
    const textFilterData = noteTitleContent?.split(',');
    const textFilter = selectFilter === ROW_FILTER_ITEMS_NAME.notes ? {
      [FORM_NAMES.noteTitleContent]: textFilterData,
      [FORM_NAMES.noteTagValue]: null,
    } : {
      [FORM_NAMES.noteTagValue]: textFilterData,
      [FORM_NAMES.noteTitleContent]: null,
    };

    setFilters({
      startDate,
      endDate,
      selectFilter,
      ...textFilter,
    });
    onChange((prev: any) => ({
      ...prev,
      startDate,
      endDate,
      noteTitleContent,
      selectFilter
    }));
    setIsVisibleCloseIconButton(true);
    setSearchIconButtonMode(SEARCH_BUTTON_MODE.withResult);
  }, [startDate, endDate, noteTitleContent, selectFilter]);

  const isSearchButtonDisable = useMemo(() => {
    return Boolean(startDate || endDate || noteTitleContent);
  }, [startDate, endDate, noteTitleContent]);

  const onCloseIconButtonClick = useCallback(() => {
    reset();
    setFilters({
      ...FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES
    });
    onChange((prev: any) => ({
      ...prev,
      ...FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES
    }));
    setIsVisibleCloseIconButton(false);
    setSearchIconButtonMode(SEARCH_BUTTON_MODE.active);
  }, []);

  return(
    <Wrapper>
      <StyledPaper>
        <HFSelect
          control={ control }
          options={ FILTER_OPTIONS }
          name={ FORM_NAMES.selectFilter }
          SelectProps={ SELECT_PROPS }
          MenuListProps={ MENU_LIST_PROPS }
        />
        <Divider $height={ '16px' }/>
        <HFTextInput
          TextFieldProps={ TEXT_FIELD_PROPS }
          inputProps={ INPUT_STYLE_PROPS }
          control={ control }
          name={ FORM_NAMES.noteTitleContent }
          placeholder={ 'Enter Search Keywords Here' }
        />
      </StyledPaper>
      <DatePickersWrapper>
        <HFDatePicker
          control={ control }
          name={ FORM_NAMES.startDate }
          DatePickerProps={ {
            placeholder: 'From'
          } }
        />
        -
        <HFDatePicker
          control={ control }
          name={ FORM_NAMES.endDate }
          DatePickerProps={ {
            placeholder: 'To'
          } }
        />
      </DatePickersWrapper>
      <BlockButton
        onClick={ onSearchButtonClick }
        disabled={ !isSearchButtonDisable }
        style={ {
          minWidth: '60px'
        } }
      >
        Search
      </BlockButton>
      { isVisibleCloseIconButton &&
        <>
          <CloseIcon>
            <Close onClick={ onCloseIconButtonClick }/>
          </CloseIcon>
        </>
      }
    </Wrapper>
  );
};