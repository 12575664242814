import { isAfter, isBefore, endOfDay } from 'date-fns';

import { compareDates } from '@/shared/utils/compareDates';

import { FILTER_ALL_BTN_LABEL } from '@constants/common';
import { FORM_DEFAULT_FIRST_LEVEL_FILTERS_VALUES, ROW_FILTER_ITEMS_NAME } from '../GeneralFilters/data';

import { FilterState, Notes } from '@modules/NotesAndHistory/components/Notes/components/NotesView/types';
import { GeneralFiltersValue } from '../GeneralFilters/types';
import { Item } from '@modules/NotesAndHistory/types';
import { NoteTagItem } from '@/shared/types/tags';

export const standardNotesProcessor = (
  creationDateFieldName: string,
  filterState: FilterState,
  notesToProcess: Notes,
  setter?: (newNotes: Notes) => void
): Notes => {
  const sortFunc = (noteA: Item, noteB:Item) => {
    return compareDates('desc')(noteA?.[creationDateFieldName as string], noteB?.[creationDateFieldName as string]);
  };

  let processed = [];

  if(!filterState || filterState === FILTER_ALL_BTN_LABEL) {
    processed = [...notesToProcess].sort(sortFunc);
  } else {
    processed = notesToProcess.filter(note => note!.noteType === filterState).sort(sortFunc);
  }
  if(setter){
    setter(processed);
  } else {
    return processed;
  }
  return processed;
};

export const generalNotesProcessor = (
  creationDateFieldName: string,
  filterState: GeneralFiltersValue,
  notesToProcess: Notes,
  setter?: (newNotes: Notes) => void
): Notes => {
  const sortFunc = (noteA: Item, noteB:Item) => {
    return compareDates('desc')(noteA?.[creationDateFieldName as string], noteB?.[creationDateFieldName as string]);
  };

  let filteredNotes = [...notesToProcess];

  if(!filterState) {
    filteredNotes = [...notesToProcess].filter((note => {
      note.noteText = note.noteText?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
      note.title = note.title?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
      return note;
    })).sort(sortFunc);
  } else {
    if(filterState.noteTitleContent) {
      const selectFilter = filterState.selectFilter;
      if(selectFilter === ROW_FILTER_ITEMS_NAME.notes) {

        filteredNotes = filteredNotes.filter(note => {
          note.noteText = note.noteText?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
          const resultNoteText = filterState.noteTitleContent?.toString().split(',').filter((text) => {
            const newText = text.trim();
            return note.noteText?.toUpperCase().indexOf(newText.toUpperCase()) >= 0;
          });

          filterState.noteTitleContent?.toString().split(',').forEach((text) => {
            const newText = text.trim();
            note.noteText = note.noteText?.replace(newText, `markedTextStart${newText}markedTextEnd`);
          });

          note.title = note.title?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
          const resultTitle = filterState.noteTitleContent?.toString().split(',').filter((text) => {
            const newText = text.trim();
            return note.title.toUpperCase().indexOf(newText.toUpperCase()) >= 0;
          });

          filterState.noteTitleContent?.toString().split(',').forEach((text) => {
            const newText = text.trim();
            note.title = note.title.replace(newText, `markedTextStart${newText}markedTextEnd`);
          });

          return resultTitle.length > 0 || resultNoteText.length > 0;
        });  
      }
      if(selectFilter === ROW_FILTER_ITEMS_NAME.tags) {
        filteredNotes = filteredNotes.filter(note => {
          const result = note.tags.filter((tag: NoteTagItem) => {
            const result = filterState.noteTitleContent?.toString().split(',').filter((text) => {
              const newText = text.trim();
              return tag.value.toUpperCase().indexOf(newText.toUpperCase()) >= 0;
            });
            return result.length > 0;
          });
          return result.length > 0;
        });  
      }
    } else if(!filterState.noteTitleContent) {
      filteredNotes = filteredNotes.filter((note => {
        note.noteText = note.noteText?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
        note.title = note.title?.replaceAll(/markedTextStart/g, '').replaceAll(/markedTextEnd/g, '');
        return note;
      })).sort(sortFunc);
    }
    filteredNotes = filteredNotes.sort(sortFunc);
  }
  
  if(setter){
    setter(filteredNotes);
  } else {
    return filteredNotes;
  }
  return filteredNotes;
};