import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import { FormProvider,useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';
import { useSalesActivePopupStore } from './store';

import { TodoPopup } from '@modules/TodoPopup';
import Modal from '@components/Modal';
import SalesPipelinePopupTopPanel from '@modules/SalesPipline/SalesPipelinePopupTopPanel';
import SalesPipelinePopupFooter from '@modules/SalesPipline/SalesPipelinePopupFooter';
import Preloader from '@components/Preloader';

import { Content, TasksAndNotes } from './components';

import { ContentWrapper, LeftColumn, RightColumn, Spacer } from './styles';

import { SalesActiveCycleFormFields, SalesActivePopupProps } from './types';

import { validationSchema } from './validationScheme';

import { MODAL_TYPE } from '@/shared/constants/modalTypes';
import { SALES_ACTIVE_POPUP_DEFAULT_VALUES } from './data';
import { SALES_ACTIVE_CYCLE_FIELD_NAMES, SALES_ACTIVE_CYCLE_STAGES_OPTIONS } from '@constants/salesActiveCycle';

import { IdType } from '@/shared/types/commonTypes';


export const SalesActivePopup = observer(({
  closeModal,
  modalProps,
}: SalesActivePopupProps) => {
  const {
    id,
    onSaveCallback,
    onDeleteCallback,
  } = modalProps;
  const isEdit = Boolean(id);
  const header = `${ isEdit ? 'Edit' : 'Add'} Cycle`;

  const {
    isHideModal,
    isLoad,
    notesUtilsStore,
    notesAndHistoryStore,
    init,
    onCancel,
    onConfirm,
    onDelete,
    removeTodo,
    resetState,
    saveTodo,
    setIsHideModal,
  } = useSalesActivePopupStore();

  const methods= useForm<SalesActiveCycleFormFields>({
    resolver: yupResolver(validationSchema),
    defaultValues: SALES_ACTIVE_POPUP_DEFAULT_VALUES
  });

  const modalStore = useStore().ModalStore;

  const onEditToDo = useCallback((id: IdType) => {
    setIsHideModal(true);
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        initLinkedContact: null,
        saveCallback: saveTodo,
        deleteCallback: removeTodo,
        onCloseAction: () => setIsHideModal(false),
      },
      component: TodoPopup
    });
  }, [ modalStore, saveTodo ]);

  useEffect(() => {
    init({
      closeTrigger: closeModal,
      id,
      onSaveCallback,
      useFormMethods: methods,
      onDeleteCallback,
    });
    return resetState;
  },[id, init, methods, onDeleteCallback, onSaveCallback, resetState]);

  const { resetTab: resetNotesAndHistoryTab } = notesAndHistoryStore;
  useEffect(() => {
    return resetNotesAndHistoryTab;
  }, [resetNotesAndHistoryTab]);

  return (
    <>
      {
        isHideModal && isLoad && <Preloader/>
      }
      <Modal
        $isHide={ isHideModal }
        headerProps={ {
          paddingLeft: '20px !important'
        } }
        isCloseDisabled={ isLoad }
        onClose={ closeModal }
        title={ header }
      >
        <FormProvider { ...methods }>
          <Modal.Content $disabled={ Boolean(notesUtilsStore.alertType) }>
            <Modal.TopPanel paddingLeft={ '20px !important' }>
              <SalesPipelinePopupTopPanel
                disabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
                name={ SALES_ACTIVE_CYCLE_FIELD_NAMES.stage }
                options={ SALES_ACTIVE_CYCLE_STAGES_OPTIONS }
              />
            </Modal.TopPanel>
            {
              isLoad &&
              <Spacer>
                <Preloader isWithoutFullPageWrapper={ true }/>
              </Spacer>
            }
            {
              !isLoad &&
              <ContentWrapper>
                <LeftColumn>
                  <Content />
                </LeftColumn>
                <RightColumn>
                  <TasksAndNotes onEditToDo={ onEditToDo }/>
                </RightColumn>
              </ContentWrapper>
            }
          </Modal.Content>
          <Modal.Footer paddingLeft={ `${isEdit ? '8px' : '12px'} !important` }>
            <SalesPipelinePopupFooter
              alertType={ notesUtilsStore.alertType }
              isDisabled={ notesUtilsStore.isNotesPanelInAddOrEditMode || isLoad }
              isEdit={ isEdit }
              onCancel={ onCancel }
              onConfirm={ onConfirm }
              onDelete={ onDelete }
              statusFieldName={ SALES_ACTIVE_CYCLE_FIELD_NAMES.salesCycleStatus }
            />
          </Modal.Footer>
        </FormProvider>
      </Modal>
    </>
  );
});
