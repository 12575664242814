import { action, flow, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { Store } from '@store';

import CommonTableStore from '@services/store/commonTableStore';

import {
  DeleteUserCustomItemParams,
  GetUserCustomFieldsResponse,
  SaveUpdateUserCustomFieldErrorResponse,
  UserCustomField,
  UserCustomFieldFormFields
} from '@/shared/types/userSettingsUserCustomFields';
import { onErrorCallback } from '@/shared/types/commonTypes';

import {
  deleteUserCustomFieldItem,
  getUserCustomFields,
  saveUserCustomField, updateUserCustomField
} from '@services/api/userSettings/userSettingsUserCustomFields';

import { getMultipleSortParams, setWhereNameAndOrderNameFilterParams } from '@/shared/utils/filterUtils';
import { MODAL_TYPE } from '@constants/modalTypes';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';

class UserSettingsUserCustomFieldsStore {
  coreStore: Store;
  table: CommonTableStore<UserCustomField>;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getUserCustomFields: flow.bound,
      getUserCustomFieldsWithLoad: flow.bound,
      init: flow.bound,
      onDelete: flow.bound,
      onSave: flow.bound,
      resetStore: action.bound,
    });

    this.coreStore = coreStore;
    this.table = new CommonTableStore<UserCustomField>({
      onPageChangeReactionCallback: this.getUserCustomFieldsWithLoad,
      onSortReactionCallback: this.getUserCustomFieldsWithLoad
    });
  }

  *getUserCustomFields() {
    try {
      const response: GetUserCustomFieldsResponse = yield getUserCustomFields({
        page: this.table.currentPage,
        ...getMultipleSortParams(this.table.multipleSorting)
      });

      this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.userCustomField], {
        page: this.table.currentPage,
        ...setWhereNameAndOrderNameFilterParams({
          whereFilters: {},
          orderFilters: this.table.multipleSorting
        }),
      });  
    
      this.table.items = response.data.data.data;
      this.table.setPaginationData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  *getUserCustomFieldsWithLoad() {
    try {
      this.coreStore.UserSettingsStore.isFetching = true;
      yield this.getUserCustomFields();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.isFetching = false;
    }
  }

  *init() {
    try {
      this.setFiltersFromServer();

      this.coreStore.UserSettingsStore.isFetching = true;
      yield this.getUserCustomFieldsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.isFetching = false;
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.userCustomField]
    ))?.value;

    this.table.onPageChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
  }

  *onSave(data: UserCustomFieldFormFields, onError: onErrorCallback) {
    try {
      this.coreStore.UserSettingsStore.isFetching = true;
      if(data.id){
        yield updateUserCustomField(data);
      } else {
        yield saveUserCustomField(data);
      }
      this.coreStore.ModalStore.closeModal(MODAL_TYPE.ADD_USER_CUSTOM_FIELD);
      yield this.getUserCustomFields();
    } catch (error) {
      const errorResponse = error as SaveUpdateUserCustomFieldErrorResponse;
      if(axios.isAxiosError(error) && (error?.response?.status === 400 || error?.response?.status === 422)) {
        const message = errorResponse.response?.data?.message.name;
        onError(message ?? '');
      }
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.isFetching = false;
    }
  }

  *onDelete(data: DeleteUserCustomItemParams) {
    try {
      this.coreStore.UserSettingsStore.isFetching = true;
      yield deleteUserCustomFieldItem(data);
      yield this.getUserCustomFields();
    } catch (error) {
      console.log(error);
    } finally {
      this.coreStore.UserSettingsStore.isFetching = false;
    }
  }

  resetStore() {
    this.table.resetTable();
  }

}

export default UserSettingsUserCustomFieldsStore;
