import React, { useCallback } from 'react';

import { NOTE_TYPES, SUB_CATEGORIES_COMMUNICATION_TYPES } from '@modules/NotesAndHistory/data';
import {
  FORM_DEFAULT_FILTERS_VALUES,
  FORM_NAMES,
  ROW_FILTER_ITEMS,
  ROW_FILTER_ITEMS_NAME,
} from '@modules/NotesAndHistory/components/Notes/components/GeneralFilters/data';

import { TwoLevelFilter } from '@modules/TwoLevelFilter';
import BubbleButton from '@components/BubbleButton';
import RowFilter from '@components/RowFilter';

import { Item, RenderItemProps } from '@components/RowFilter/types';
import { GeneralRowFilterProps } from './types';


export const GeneralRowFilter = ({
  filters,
  onChange,
  setFilters,
}: GeneralRowFilterProps) => {

  const onNoteFilterChange = (
    (setCurrentSelected: RenderItemProps['setCurrentSelected']) => (_: any, value: Item) => {
      if(setCurrentSelected) {
        setCurrentSelected(value);
      }
      setFilters({ [FORM_NAMES.noteEntity]: value });
      onChange((prev: any) => ({
        ...prev,
        [FORM_NAMES.noteEntity]: value
      }));
    });

  const onCommunicationFilterChange = (
    (setCurrentSelected: RenderItemProps['setCurrentSelected']) => (_: any, value: Item) => {
      if(setCurrentSelected) {
        setCurrentSelected(value);
      }
      setFilters({ [FORM_NAMES.noteType]: value });
      onChange((prev: any) => ({
        ...prev,
        [FORM_NAMES.noteType]: value
      }));
    });

  const onAllButtonClick = useCallback(() => {
    setFilters({
      [FORM_NAMES.noteEntity]: FORM_DEFAULT_FILTERS_VALUES.noteEntity,
      [FORM_NAMES.noteType]: FORM_DEFAULT_FILTERS_VALUES.noteType,
    });
    onChange((prev: any) => ({
      ...prev,
      [FORM_NAMES.noteEntity]: FORM_DEFAULT_FILTERS_VALUES.noteEntity,
      [FORM_NAMES.noteType]: FORM_DEFAULT_FILTERS_VALUES.noteType,
    }));
  }, [setFilters, onChange]);

  return(
    <RowFilter
      allButtonLabel={ 'All' }
      items={ ROW_FILTER_ITEMS }
      boxProps={ {
        justifyContent: 'flex-start'
      } }
      limit={ 10 }
      initState={ filters?.noteType || filters?.noteEntity || null }
      onChange={ onAllButtonClick }
      renderItem={ ({
        content,
        currentSelected,
        indexForKey,
        isSelected,
        isShowMore,
        onClick,
        setCurrentSelected
      }) => {

        if(content === ROW_FILTER_ITEMS_NAME.notes) {
          return (
            <TwoLevelFilter
              StyleBlockButtonProps={ {
                marginRight: '3px',
              } }
              $isAfterLine={ false }
              allButtonLabel={ NOTE_TYPES.all }
              categoryName={ content }
              initCategory={ filters?.noteEntity || null }
              isMultiplyFilter={ true }
              onTypeFilterChange={ onNoteFilterChange(setCurrentSelected) }
              selectedCategory={ currentSelected || filters?.noteEntity || null }
              subCategoryOptions={ NOTE_TYPES }
            />
          );
        }

        if(content === ROW_FILTER_ITEMS_NAME.communications) {
          return (
            <TwoLevelFilter
              $isAfterLine={ false }
              allButtonLabel={ SUB_CATEGORIES_COMMUNICATION_TYPES.all }
              categoryName={ content }
              initCategory={ filters?.noteType || null }
              isMultiplyFilter={ true }
              onTypeFilterChange={ onCommunicationFilterChange(setCurrentSelected) }          
              selectedCategory={ currentSelected || filters?.noteType || null }
              subCategoryOptions={ SUB_CATEGORIES_COMMUNICATION_TYPES }
            />
          );
        }

        return (
          <BubbleButton
            $isActive={ isSelected && !isShowMore }
            $isSecondActiveStyle={ isSelected && isShowMore }
            key={ indexForKey }
            onClick={ onClick }
            BtnProps={ {
              style: {
                marginRight: '8px'
              }
            } }
          >
            {content}
          </BubbleButton>
        );
      }
      }
    />
  );
};