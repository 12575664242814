import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;

  & > div {
    padding-right: 20px;
  }
`;

export const FieldsRow = styled.div`
  margin-top: 15px;
`;

export const TextEditorWrapper = styled.div`
  height: 240px;
  margin-bottom: 12px;
`;

export const TagsWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-bottom: 20px;
  
  &  button {
    margin-left: auto;
  }
`;
