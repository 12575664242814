import React, { useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useStore } from '@store';

import { useTriggerRef } from '@customHooks';

import BlockButton from '@components/BlockButton';
import HelperText from '@components/HelperText';
import HFDatePicker from '@modules/HookFormComponents/HFDatePicker';

import { getValidationScheme } from './validationScheme';
import { getDateIsValidOrNull } from './utils';

import { Restore } from '@/assets';

import { DateFilters as DataFiltersType } from '@/shared/services/store/trashStore/types';
import { TrashFiltersNames } from '@pages/Trash/types';

import { ApplyBtnWrapper, DatePickerWrapper, Wrapper } from './styles';


export const DateFilters = () => {
  const trashStore = useStore().TrashStore;

  const { currentTrashView } = trashStore;

  const {
    useSetTriggerRefOnce,
    triggerRef
  } = useTriggerRef();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger
  } = useForm<DataFiltersType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(getValidationScheme(
      {
        startDate: TrashFiltersNames.Start_Date,
        endDate: TrashFiltersNames.End_Date
      },
      triggerRef
    ))
  });

  const [ startDateString, endDateString ] = useWatch({
    control,
    name: [ TrashFiltersNames.Start_Date, TrashFiltersNames.End_Date, ]
  });

  const isDisabled = Object.values(errors).length > 0;

  const maxDateDatePickerProps = useMemo(() => {
    const startDate = getDateIsValidOrNull(startDateString);
    const today = new Date();

    if (!startDate) {
      return {
        maxDate: today,
        initialFocusedDate: today
      };
    }

    return {
      maxDate: today,
      minDate: startDate,
      initialFocusedDate: today
    };
  }, [ startDateString ]);

  const minDateDatePickerProps = useMemo(() => {
    const endDate = getDateIsValidOrNull(endDateString);
    const today = new Date();
    return {
      maxDate: endDate || today,
      initialFocusedDate: endDate || today
    };
  }, [ endDateString ]);

  const onSubmit = handleSubmit((data) => {
    trashStore.setDateFilters(data);
  });

  useSetTriggerRefOnce(trigger);

  useEffect(() => {
    reset({
      [TrashFiltersNames.Start_Date]: '',
      [TrashFiltersNames.End_Date]: ''
    });
  }, [ currentTrashView, reset ]);

  return (
    <Wrapper>
      <DatePickerWrapper>
        <HFDatePicker
          DatePickerProps={ minDateDatePickerProps }
          control={ control }
          name={ TrashFiltersNames.Start_Date }
          showError={ false }
        />
      </DatePickerWrapper>
      <HelperText
        margin={ 'auto 10px' }
        text={ 'to' }
      />
      <DatePickerWrapper>
        <HFDatePicker
          DatePickerProps={ maxDateDatePickerProps }
          control={ control }
          name={ TrashFiltersNames.End_Date }
          preventMinDateAutoSet={ true }
          showError={ false }
        />
      </DatePickerWrapper>
      <ApplyBtnWrapper>
        <BlockButton
          disabled={ isDisabled }
          onClick={ onSubmit }
          startIcon={ <Restore/> }
        >
          Apply
        </BlockButton>
      </ApplyBtnWrapper>
    </Wrapper>
  );
};

