import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { useStore } from '@store';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';
import { MODAL_TYPE } from '@constants/modalTypes';

import { BusinessDetails, ContactInformation } from './components';
import { TodoPopup } from '@modules/TodoPopup';
import NotesAndHistory, { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';
import Surface from '@components/BasicLayoutComponents/Surface';

import { NotesAndHistoryWrapper, OverviewWrapper, SURFACE_CONTENT_WRAPPER_STYLE_PROPS } from './styles';

import { ContactDetailsOverviewProps } from './types';
import { ContactOverviewFormFields } from '@/shared/types/contactOverview';
import { IdType } from '@/shared/types/commonTypes';


export const ContactDetailsOverview = observer(({
  contactId,
}: ContactDetailsOverviewProps) => {
  const modalStore = useStore().ModalStore;
  const contactDetailsOverview = useStore().ContactDetailsOverview;
  const {
    contact,
    init,
    notesAndHistoryStore,
    notesUtilsStore,
    predefinedTags,
    removeTodo,
    resetStore,
    saveTodo,
  } = contactDetailsOverview;

  const methods = useForm<ContactOverviewFormFields>();
  const { profile } = useStore().SettingsStore;

  const onEditToDo = useCallback((id: IdType) => {
    modalStore.openModal({
      modalType: MODAL_TYPE.TODO_TASK_MODAL,
      modalProps: {
        id,
        initLinkedContact: null,
        saveCallback: saveTodo,
        deleteCallback: removeTodo,
      },
      component: TodoPopup
    });
  }, [ modalStore, removeTodo, saveTodo ]);

  useEffect(() => {
    init(contactId, methods);
    return resetStore;
    // eslint-disable-next-line
  }, [contactId]);

  if(!contact) {
    return null;
  }

  return (
    <Surface.Content
      style={ SURFACE_CONTENT_WRAPPER_STYLE_PROPS }>
      <OverviewWrapper>
        <ContactInformation/>
        <BusinessDetails/>
        <NotesAndHistoryWrapper>
          {/* TODO: note refactoring */}
          {/* @ts-ignore */}
          <NotesAndHistory
            control={ methods.control }
            currentUser={ profile }
            instanceStore={ notesAndHistoryStore }
            isAddNoteBtnDisabled={ false }
            linkedContactData={ contact }
            names={ CONTACT_NOTES_AND_HISTORY_INPUT_NAMES }
            noteAndHistoryType={ NOTE_AND_HISTORY_TYPE.general }
            noteContactOptions={ notesUtilsStore.noteContactOptions }
            onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
            onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
            onEntityIdClick={ onEditToDo }
            predefinedTags={ predefinedTags }
            title={ 'Notes & History Timeline ' }
          />
        </NotesAndHistoryWrapper>
      </OverviewWrapper>
    </Surface.Content>
  );
});
