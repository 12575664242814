import { ValueOf } from '@/shared/types/commonTypes';

import { ACCOUNT_AND_PLANS_URLS } from '@services/api/accountAndPlans/accountAndPlans';
import { TO_DOS_URLS } from '@services/api/todos/todos';
import { TRASH_URLS } from '@services/api/trash/trash';
import { TrashTabValues } from '@/pages/Trash/types';

const ALL_CONTACTS_PAGE_URL = 'api/crm/contact/gridList';
const CALENDAR_PAGE_URL = 'api/activity/getCalendarList';
const CLIENT_OPPORTUNITIES_PAGE_URL = 'api/opportunity/getGrid';
const CONTACT_PROFILE_ACTIVITIES_CONTACTS_PAGE_URL = 'api/activity/gridList';
const CONTACT_PROFILE_CUSTOM_FIELD_PAGE_URL = 'api/customField/getCustomFieldByType';
const CONTACT_PROFILE_EMPLOYEES_CONTACTS_PAGE_URL = 'api/contact/employee/getByContact';
const CONTACT_PROFILE_RELATED_CONTACTS_PAGE_URL = 'api/contact/related/getByContact';
const DASHBOARD_PAGE_URL = 'api/contact/getDashboardForContactsGraph';
const LIST_CONTACTS_PAGE_URL = 'api/list/listContactsGrid';
const LIST_MANAGER_PAGE_URL = 'api/list/gridList';
const SALES_PIPELINE_PAGE_URL = 'api/pipeline/getGrid';
const USER_CUSTOM_FIELD_PAGE_URL = 'api/customField/list';
const USERS_LIST_PAGE_URL = 'api/crm/client/list';


export const MODULES_NAMES = {
  accountAndPlans: 'Account and Plans',
  allContacts: 'All Contacts',
  calendar: 'Calendar',
  clientOpportunities: 'Client Opportunities',
  contactProfileAccounts: 'Contact Profile Accounts',
  contactProfileActivities: 'Contact Profile Activities',
  contactProfileCustomField: 'Contact Profile Custom Field',
  contactProfileEmployees: 'Contact Profile Employees',
  contactProfileRelatedContacts: 'Contact Profile Related Contacts',
  contactProfileToDos: 'Contact Profile ToDos',
  dashboard: 'Dashboard',
  listContacts: 'List Contacts',
  listManager: 'List Manager',
  otherTasks: 'Other Tasks',
  salesCycle: 'Sales Cycle',
  toDos: 'To-Dos',
  userCustomField: 'User Custom Field',
  usersList: 'Users List',
  [TrashTabValues.AccountsAndPlans]: 'Trash Accounts and Plans',
  [TrashTabValues.Activities]: 'Trash Activities',
  [TrashTabValues.ClientOpportunities]: 'Trash Client Opportunities',
  [TrashTabValues.Contacts]: 'Trash Contacts',
  [TrashTabValues.Lists]: 'Trash Lists',
  [TrashTabValues.SalesCycles]: 'Trash Sales Cycles',
  [TrashTabValues.Tasks]: 'Trash Tasks',
} as const;

export const URLS: Record<ValueOf<typeof MODULES_NAMES>, string> = {
  [MODULES_NAMES.accountAndPlans]: ACCOUNT_AND_PLANS_URLS.getAccountAndPlans,
  [MODULES_NAMES.allContacts]: ALL_CONTACTS_PAGE_URL,
  [MODULES_NAMES.calendar]: CALENDAR_PAGE_URL,
  [MODULES_NAMES.clientOpportunities]: CLIENT_OPPORTUNITIES_PAGE_URL,
  [MODULES_NAMES.contactProfileAccounts]: ACCOUNT_AND_PLANS_URLS.getContactsAccountAndPlans,
  [MODULES_NAMES.contactProfileActivities]: CONTACT_PROFILE_ACTIVITIES_CONTACTS_PAGE_URL,
  [MODULES_NAMES.contactProfileCustomField]: CONTACT_PROFILE_CUSTOM_FIELD_PAGE_URL,
  [MODULES_NAMES.contactProfileEmployees]: CONTACT_PROFILE_EMPLOYEES_CONTACTS_PAGE_URL,
  [MODULES_NAMES.contactProfileRelatedContacts]: CONTACT_PROFILE_RELATED_CONTACTS_PAGE_URL,
  [MODULES_NAMES.contactProfileToDos]: TO_DOS_URLS.getContactsTodos,
  [MODULES_NAMES.dashboard]: DASHBOARD_PAGE_URL,
  [MODULES_NAMES.listContacts]: LIST_CONTACTS_PAGE_URL,
  [MODULES_NAMES.listManager]: LIST_MANAGER_PAGE_URL,
  [MODULES_NAMES.otherTasks]: TO_DOS_URLS.getOtherTodos,
  [MODULES_NAMES.salesCycle]: SALES_PIPELINE_PAGE_URL,
  [MODULES_NAMES.toDos]: TO_DOS_URLS.getToDos,
  [MODULES_NAMES.userCustomField]: USER_CUSTOM_FIELD_PAGE_URL,
  [MODULES_NAMES.usersList]: USERS_LIST_PAGE_URL,
  [MODULES_NAMES[TrashTabValues.AccountsAndPlans]]: TRASH_URLS.getAccountsAndPlansForTrash,
  [MODULES_NAMES[TrashTabValues.Activities]]: TRASH_URLS.getActivitiesForTrash,
  [MODULES_NAMES[TrashTabValues.ClientOpportunities]]: TRASH_URLS.getClientOpportunitiesForTrash,
  [MODULES_NAMES[TrashTabValues.Contacts]]: TRASH_URLS.getContactsForTrash,
  [MODULES_NAMES[TrashTabValues.Lists]]: TRASH_URLS.getListsForTrash,
  [MODULES_NAMES[TrashTabValues.SalesCycles]]: TRASH_URLS.getSalesCyclesForTrash,
  [MODULES_NAMES[TrashTabValues.Tasks]]: TRASH_URLS.getTasksForTrash,
};
