import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import debounce from 'lodash/debounce';
import axios from 'axios';

import { Store } from '@store';

import {
  addContactsToList,
  cloneList,
  deleteFolderWithMoveChildren,
  deleteLists,
  getLists,
  saveFolder,
  saveList,
  saveMultiFolder,
  saveToParent,
  updateListFlags,
} from '@services/api/lists/lists';

import CommonTableStore from '@services/store/commonTableStore';

import { convertObjectWithNumericKeysToArray } from '@/shared/utils/convertObjectWithNumericKeysToArray';
import {
  getMultipleSortParams,
  getFilterParams,
  getFiltersCount,
  getGlobalFlagged,
  setWhereNameAndOrderNameFilterParams,
} from '@/shared/utils/filterUtils';
import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { convertsFilterData } from './utils';

import { TypeFilterType } from './types';
import {
  AddContactsToListData,
  CloneListParams,
  ConvertedListsFiltersData,
  DeleteFolderProps,
  LisSaveErrorData,
  ListsFiltersType,
  ListsGridItemListType,
  ListsGridItemType,
  ListsGridResponse,
  MoveToAnotherFolderProps, MultiFolderResponse,
  MultiFolderSaveParams,
  Parent,
  SaveFolderData,
  SaveListData,
  SaveToParentParams,
} from '@/shared/types/lists';
import { ItemWithId, ValueOf } from '@/shared/types/commonTypes';

import { INIT_FILTERS_DATA_STATE, INIT_FILTERS_STATE } from './data';
import { LISTS_TABLE_FILTER_NAMES, LISTS_TYPES, NEW_FOLDER_FRONT_ID_KEY, TYPE_FIELDS } from '@constants/lists';
import { MODAL_TYPE } from '@constants/modalTypes';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { ListsNotificationHelper } from '../../listsNotificationHelper';

import { NOTIFICATION_TYPES } from '@constants/notifications';
import { ENTITY_NAMES } from '@constants/common';


class AllListsStore {
  coreStore: Store;
  filters: ListsFiltersType = INIT_FILTERS_STATE;
  filtersData: ConvertedListsFiltersData = INIT_FILTERS_DATA_STATE;
  folderId: null | string = null;
  isAllListsLoad: boolean = true;
  isFiltersOpen: boolean = false;
  isPageActive: boolean = false;
  onFiltersChangeReaction: IReactionDisposer;
  onTypeFilterChangeReaction: IReactionDisposer;
  parent: Parent = null;
  table: CommonTableStore<ListsGridItemType>;
  typeFilter: TypeFilterType = null;
  asyncRequestExecutor: AsyncRequestExecutor;
  listNotificationHelper: ListsNotificationHelper;


  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      addContactsToList: flow.bound,
      addNewList: flow.bound,
      addUpdateFolder: flow.bound,
      deleteFolder: flow.bound,
      getLists: flow.bound,
      getListsWithLoad: flow.bound,
      init: flow.bound,
      updateFlags: flow.bound,
    });

    this.coreStore  = coreStore;
    this.table = new CommonTableStore<ListsGridItemType>({
      onGlobalFlaggedChangeReactionCallback: this.getListsWithLoad,
      onPageChangeReactionCallback: this.getListsWithLoad,
      onSortReactionCallback: this.getListsWithLoad
    });

    this.listNotificationHelper = new ListsNotificationHelper(this.coreStore.NotificationsStore,);

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onTypeFilterChangeReaction = this.createOnTypeFilterReaction();
  }

  *addNewList(data: SaveListData, setNameError: (errorText: string) => void){
    let isValidationError = false;

    try {
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveList(data),
        onError: () => this.listNotificationHelper.create({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.listNotificationHelper.create({ status: NOTIFICATION_TYPES.success })
      });

      this.coreStore.ModalStore.closeModal(MODAL_TYPE.ADD_LISTS_ITEM);

      this.isAllListsLoad = true;
      yield this.getListsWithLoad();
    } catch (error) {
      if(axios.isAxiosError(error)){
        isValidationError = true;
        const errorResponse = error.response?.data as LisSaveErrorData;
        setNameError(errorResponse.data.error.name);
        this.asyncRequestExecutor.clearCallbacks();
        return;
      }
      console.log(error);
    } finally {
      yield this.isAllListsLoad = false;
      if(!isValidationError){
        this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
      }
    }
  }

  *addUpdateFolder(data: SaveFolderData){
    try {
      this.isAllListsLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveFolder(data),
        onError: () => this.listNotificationHelper.createUpdateNotification({
          isError: true,
          isUpdate: Boolean(data?.id),
          otherEntityName: ENTITY_NAMES.folder,
        }),
        onSuccess: () => this.listNotificationHelper.createUpdateNotification({
          isError: false,
          isUpdate: Boolean(data?.id),
          otherEntityName: ENTITY_NAMES.folder,
        }),
      });

      yield this.getListsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *addContactsToList(data: AddContactsToListData, callback?: () => void){
    try {
      if(callback) { callback(); }

      this.isAllListsLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => addContactsToList(data),
        onError: () => this.listNotificationHelper.addContactsToList({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.listNotificationHelper.addContactsToList({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getListsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  get globalBookmarkState () {
    const onlyLists = this.table.items.filter((item) => {
      return item.typeList !== LISTS_TYPES.Folder;
    }) as ListsGridItemListType[];
    return onlyLists.length > 0 && onlyLists.every((item) => Boolean(item.flagged));
  }

  *getLists(){
    if(!this.isPageActive){
      return;
    }

    try {
      const start = async () => {
        this.table.clearItems(true);

        const response: ListsGridResponse = await getLists({
          page: this.table.currentPage,
          ...(this.folderId ? { parentId: this.folderId } : {}),
          ...getFilterParams(this.typeFilter),
          ...getFilterParams(this.filters),
          ...getMultipleSortParams(this.table.multipleSorting),
          ...getGlobalFlagged(this.table.globalFlagged),
        });

        this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.listManager], {
          page: this.table.currentPage,
          ...setWhereNameAndOrderNameFilterParams({
            whereFilters: {
              ...this.filters,
              [LISTS_TABLE_FILTER_NAMES.typeList]: [this.typeFilter?.typeList],
              flagged: [Number(this.table.globalFlagged)]
            },
            orderFilters: this.table.multipleSorting
          }),
        });

        this.parent = response.data.data.data.parent;

        const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
        this.filtersData = convertsFilterData(response.data.data.data.filterData, currentUserId);

        this.table.setPaginationData(response.data.data);
        this.table.checkAndSetIfPageOutOfRange();
        this.table.items = convertObjectWithNumericKeysToArray<ListsGridItemType>(response.data.data.data);
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.listNotificationHelper.load({
          status: NOTIFICATION_TYPES.error,
          uniqueKey: Math.random() * 10000
        }),
      });

    } catch (error) {
      console.log(error);
    }
  }

  *getListsWithLoad() {
    try {
      this.isAllListsLoad = true;
      yield this.getLists();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *init(folderId?: string) {
    try {
      this.isPageActive = true;
      if(folderId){
        this.folderId = folderId;
      }
      this.isAllListsLoad = true;

      this.setFiltersFromServer();

      yield this.getLists();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.listManager]
    ))?.value;

    this.onFiltersChangeReaction();
    this.onTypeFilterChangeReaction();
    this.table.onPageChangeReaction();
    this.table.onGlobalFlaggedChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);

      if(serverFilterValue.filters?.where) {
        const {
          classification,
          flagged,
          listOwner,
          officePrimaryAdvisor,
          status,
          typeList,
        } = serverFilterValue.filters.where;

        this.setTypeFilter(typeList?.[0]);
        this.filters = {
          [LISTS_TABLE_FILTER_NAMES.classification]: classification,
          [LISTS_TABLE_FILTER_NAMES.officePrimaryAdvisor]: officePrimaryAdvisor,
          [LISTS_TABLE_FILTER_NAMES.listOwner]: listOwner,
          [LISTS_TABLE_FILTER_NAMES.name]: '',
          [LISTS_TABLE_FILTER_NAMES.status]: status,
        };
        this.table.setGlobalFlaggedFilters(Boolean(flagged?.[0]) || false);
      }
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onGlobalFlaggedChangeReaction = this.table.createOnGlobalFlaggedChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onTypeFilterChangeReaction = this.createOnTypeFilterReaction();
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if(this.isPageActive){
          this.table.setCurrentPageWithoutReaction(1);
          this.getListsWithLoad();
        }
      }, 1500),
    );
  }

  createOnTypeFilterReaction() {
    return reaction(
      () => this.typeFilter ,
      () => {
        if(this.isPageActive){
          this.table.setCurrentPageWithoutReaction(1);
          this.getListsWithLoad();
        }
      }
    );
  }

  *deleteLists(ids: Array<string>){
    try {
      this.isAllListsLoad = true;

      const countOfEntities = ids.length;
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () =>  (
          deleteLists({
            ids
          })
        ),
        onError: () => this.listNotificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities
        }),
        onSuccess: () => this.listNotificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities
        })
      });

      yield this.getLists();

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *cloneList(data: CloneListParams){
    try {
      this.isAllListsLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () =>  cloneList(data),
        onError: () => this.listNotificationHelper.clone({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.listNotificationHelper.clone({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getListsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *deleteFolder(data: DeleteFolderProps){
    try {
      this.isAllListsLoad = true;

      if(typeof data.parentId === 'number' || typeof data.parentId === 'object'){
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => deleteFolderWithMoveChildren({
            id: data.id,
            // this assertion need to pass null like string because axios ignores null and undefined as param value
            parentId: data.parentId || ('null' as never as null)
          }),
          onError: () => this.listNotificationHelper.deleteFolder({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.listNotificationHelper.deleteFolder({ status: NOTIFICATION_TYPES.success })
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => deleteLists({
            ids: [
              data.id.toString()
            ]
          }),
          onError: () => this.listNotificationHelper.deleteFolder({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.listNotificationHelper.deleteFolder({ status: NOTIFICATION_TYPES.success })
        });
      }
      yield this.getListsWithLoad();

      this.table.checkAndSetIfPageOutOfRange();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  get filtersCount () {
    return getFiltersCount(this.filters);
  }

  resetState = () => {
    this.onFiltersChangeReaction();
    this.onTypeFilterChangeReaction();

    this.folderId = null;
    this.isFiltersOpen = false;
    this.isPageActive = false;
    this.resetFilters();
    this.table.resetTable();
    this.typeFilter = null;

    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onTypeFilterChangeReaction = this.createOnTypeFilterReaction();
  };

  resetFilters = () => {
    this.filters = INIT_FILTERS_STATE;
  };

  *moveToAnotherFolder (data: SaveToParentParams){
    try {
      this.isAllListsLoad = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveToParent(data),
        onError: () => this.listNotificationHelper.moveToFolder({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.listNotificationHelper.moveToFolder({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getListsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *moveToAnotherFolderAndCreateFolder (data: MoveToAnotherFolderProps){
    try {
      const { closeModal, newFolders, saveTarget, listItem } = data;
      closeModal();
      this.isAllListsLoad = true;

      const sanitizedArrOfNewFolders: MultiFolderSaveParams = newFolders.map(item => {
        return {
          name: item.name,
          parentId: null,
          typeField: TYPE_FIELDS.Folder
        };
      });

      const isNewFolder = NEW_FOLDER_FRONT_ID_KEY in saveTarget;
      let newFolderBackendId = null;

      if(isNewFolder) {
        const response:MultiFolderResponse = yield saveMultiFolder(sanitizedArrOfNewFolders);
        const index = response.data.data.findIndex((item) => item.name === saveTarget.name);

        if(index >= 0){
          newFolderBackendId = response.data.data[index].id;
        }
      }

      yield saveToParent({
        id: listItem.id,
        parentId: isNewFolder ? newFolderBackendId : saveTarget.id
      });

      yield this.getListsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isAllListsLoad = false;
    }
  }

  setFilterState = (name: ValueOf<typeof LISTS_TABLE_FILTER_NAMES>, value: Array<string> | string |  null) => {
    this.filters = {
      ...this.filters,
      [name]: value
    };
  };

  setTypeFilter = (value: string | null) => {
    if(!value) {
      this.typeFilter = null;
    } else {
      this.typeFilter = {
        [LISTS_TABLE_FILTER_NAMES.typeList]: value
      };
    }
  };

  toggleFilterState = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean){
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield updateListFlags(params);

      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, {
          flagged: Number(state)
        });
      });
      if(this.table.globalFlagged) {
        this.getListsWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default AllListsStore;
