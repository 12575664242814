import React, { useCallback, useRef } from 'react';
import { PaperProps, Popper, PopperProps } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { useLazyLoading } from '@/shared/customHooks/useLazyLoading';

import { Search } from '@/assets';

import { StyledAutocomplete, StyledPaper, DisablePortalWrapper } from './styles';

import { CustomAutocompleteProps } from './types';

const MIN_PADDING_SIZE = 10;

export const CustomAutocomplete = ({
  AutocompleteProps,
  inputProps,
  InputProps,
  PopperStyledProps = {},
  TextFieldProps,
  onScrollToBottomAction = () => {},
  showError,
}: CustomAutocompleteProps) => {

  const [onScroll, containerRef] = useLazyLoading<HTMLElement>({
    onIntersection: onScrollToBottomAction,
    delay: 0
  });

  const ref = useRef<HTMLElement | null>(null);

  const renderAutocomplete = useCallback(() => (
    <StyledAutocomplete
      $showError={ showError }
      ref={ ref }
      PopperComponent={ (props: PopperProps) => (
        <Popper
          { ...props }
          placement={ 'bottom-end' }
          style={ {
            width: ref ? ref.current?.clientWidth : 'auto',
            ...PopperStyledProps
          } }
        />
      ) }
      PaperComponent={ (props: PaperProps) => <StyledPaper { ...props }/> }
      renderInput={ (params) => (
        <TextField
          variant='outlined'
          { ...params }
          inputProps={ {
            ...params.inputProps,
            ...inputProps
          } }
          InputProps={ {
            ...params.InputProps,
            ...InputProps,
            ...(
              InputProps && 'endAdornment' in InputProps
                ? { endAdornment: InputProps.endAdornment || params.InputProps.endAdornment }
                : { endAdornment : <Search /> }
            )
          } }
          { ...TextFieldProps }
        />
      ) }
      { ...AutocompleteProps }
      ListboxProps={ {
        ...AutocompleteProps.ListboxProps,
        ref: containerRef,
        onScroll
      } }
      // onHighlightChange={ (event: object, option: any, reason: string) => {} }
    />
  ),[AutocompleteProps, InputProps, TextFieldProps, showError]);

  if(AutocompleteProps?.disablePortal){
    return (
      <DisablePortalWrapper $showError={ showError }>
        { renderAutocomplete() }
      </DisablePortalWrapper>
    );
  }

  return renderAutocomplete();
};
