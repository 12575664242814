import React from 'react';

import BlockButton, { COLOR_SCHEME_TYPES } from '@components/BlockButton';
import Box from '@material-ui/core/Box';

import { PopupActionsButtonsType } from './types';

const saveClickStyle =  {
  marginLeft: 20,
};

export const PopupActionsButtons = ({
  onCancelClick,
  onSaveClick,
  CancelProps,
  SaveProps,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  wrapperProps,
  disabled = false
}: PopupActionsButtonsType) => (
  <Box { ...wrapperProps }>
    <BlockButton
      colorSchema={ COLOR_SCHEME_TYPES.outlined }
      disabled={ disabled }
      onClick={ onCancelClick }
      { ...CancelProps }
    >
      {cancelLabel}
    </BlockButton>
    <BlockButton
      colorSchema={ COLOR_SCHEME_TYPES.contained }
      disabled={ disabled }
      onClick={ onSaveClick }
      style={ saveClickStyle }
      { ...SaveProps }
    >
      {saveLabel}
    </BlockButton>
  </Box>
);
