import React, { useCallback, useMemo } from 'react';
import { useStore } from '@store';
import { observer } from 'mobx-react';

import { UserPlus, Close, PlusWithCircle } from '@/assets';

import BlockButton from '@components/BlockButton';
import CustomToggle, { useCustomToggleState } from '@components/CustomToggle';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import { UserSelectFilter } from '@modules/UserSelectFilter';

import { ListContactsPretableHeaderProps } from './types';

import { ManualItemsWrapper, ActionsWrapper } from './styles';

import { LIST_CONTACTS_TABLE_FILTER_NAMES, LISTS_TYPES } from '@constants/lists';
import { IdType } from '@/shared/types/commonTypes';

export const ListContactsPretableHeader = observer(({
  listType,
  onContactAddClick,
  onRemoveContactsClick,
}: ListContactsPretableHeaderProps) => {
  const listContactsStore = useStore().ListsStore.ListContactsStore;
  const {
    currentListDetails,
    filters,
    filtersData,
    isContactManagement,
    isLoad,
    setIsContactManagement,
    table,
  } = listContactsStore;

  const contactIdsAddToList = useMemo(() => {
    if(!isContactManagement){
      return [];
    }

    return table.items.reduce((acc: Array<IdType>, item) => {
      if(!item?.contactInList&& table.selectedIDs.includes(item.id)){
        acc.push(item.id);
      }
      return acc;
    }, []);
  }, [isContactManagement, table.items, table.selectedIDs]);

  const contactIdsRemoveFromList = useMemo(() => {
    if(!isContactManagement){
      return table.selectedIDs;
    }

    return table.items.reduce((acc: Array<IdType>, item) => {
      if(Boolean(item?.contactInList) && table.selectedIDs.includes(item.id)){
        acc.push(item.id);
      }
      return acc;
    }, []);
  }, [isContactManagement, table.items, table.selectedIDs]);

  const onContactsAddToList = useCallback(() => {
    listContactsStore.addContactsToList(
      {
        id: currentListDetails!.id as number,
        contacts: contactIdsAddToList
      }
    );
  }, [contactIdsAddToList, currentListDetails, listContactsStore]);

  const onContactsRemoveFromList = useCallback(() => {
    onRemoveContactsClick(contactIdsRemoveFromList as Array<string>);
  }, [contactIdsRemoveFromList, onRemoveContactsClick]);

  const onChangeUsersFilter = useCallback((newValue: Array<string>) => {
    listContactsStore.setFilterState(
      LIST_CONTACTS_TABLE_FILTER_NAMES.officePrimaryAdvisor,
      newValue
    );
  }, [listContactsStore]);

  const { checked, onChange } = useCustomToggleState({
    initValue: isContactManagement,
    onChangeCb: ({ isChecked }) => setIsContactManagement(isChecked)
  });

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive: table.globalFlagged,
        onStateChange: table.setGlobalFlaggedFilters,
      } }
    >
      <UserSelectFilter
        closeTrigger={ isLoad }
        onChange={ onChangeUsersFilter }
        usersOptions={ filtersData.officePrimaryAdvisor }
        value={ filters?.[LIST_CONTACTS_TABLE_FILTER_NAMES.officePrimaryAdvisor] || [] }
        withAllUserOption={ true }
      />
      {
        (contactIdsAddToList.length > 0 || contactIdsRemoveFromList.length > 0) &&
        <ActionsWrapper>
          {
            // TODO tooltip and hide button;
            contactIdsAddToList.length > 0 &&
            <BlockButton
              onClick={ onContactsAddToList }
              startIcon={ <PlusWithCircle /> }
            >
              {
                `Add to List ${contactIdsAddToList.length} Selected ${ 
                  contactIdsAddToList.length > 1 
                    ? 'Contacts' 
                    : 'Contact' 
                }`
              }
            </BlockButton>
          }
          {
            listType === LISTS_TYPES.Manual && contactIdsRemoveFromList.length > 0 &&
            <BlockButton
              onClick={ onContactsRemoveFromList }
              startIcon={ <Close /> }
            >
              {
                `Remove from List ${contactIdsRemoveFromList.length} Selected ${
                  contactIdsRemoveFromList.length > 1
                    ? 'Contacts'
                    : 'Contact'
                }`
              }
            </BlockButton>
          }
        </ActionsWrapper>
      }
      {
        listType === LISTS_TYPES.Manual &&
        <ManualItemsWrapper>
          <CustomToggle
            $fluid={ false }
            checked={ checked }
            label={ 'Contact Management' }
            labelPlacement={ 'end' }
            onChange={ onChange }
          />

          <BlockButton
            disabled={ isContactManagement }
            onClick={ onContactAddClick }
            startIcon={ <UserPlus /> }
          >
            Add Contact
          </BlockButton>
        </ManualItemsWrapper>
      }
    </PreTableWrapper>
  );
});
