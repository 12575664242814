import styled from 'styled-components';

export const NameColumn = styled.div`
  width: 33%;
  padding-left: 5px;
`;

export const TypeColumn = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
`;

export const StageColumn = styled.div`
  width: 17%;
`;

export const StatusColumn = styled.div`
  width: 18%;
  display: flex;
  justify-content: center;
`;

export const AssignedToColumn = styled.div`
  display: flex;
  justify-content: start;
  width: 18%;
`;

export const AmountColumn = styled.div`
  width: 15%;
`;

export const EntityNameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 40px;
`;
