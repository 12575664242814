import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import CustomTabs from '@components/CustomTabs';
import Tooltip from '@components/Tooltip';
import { AddNoteButton } from '..';

import { NOTES_TAB_VALUE, VIEWS } from '../../data';

import { Wrapper, AddButtonWrapper } from './styles';

import { TabsComponentProps } from '../../types';

export const Tabs = observer(({
  disableTooltipText,
  instanceStore,
  isAddButtonDisabled,
  tabs,
}: TabsComponentProps) => {
  const {
    currentTab,
    setCurrentTab,
    notesView,
    onAddNoteClick,
  } = instanceStore;

  const onTabChange = useCallback((e: any, value: string) => setCurrentTab(value),[setCurrentTab]);

  if (notesView === VIEWS.addEdit) {
    return  null;
  }

  return (
    <Wrapper>
      <CustomTabs
        $hideIndicator={ true }
        $overFlow={ 'unset' }
        onChange={ onTabChange }
        value={ currentTab }
      >
        {
          tabs.map((item, index) => (
            <CustomTabs.RealWorldTab
              $isFirst={ index === 0 }
              $maxZIndex={ tabs.length }
              $withBorders={ true }
              $zIndex={ tabs.length - index }
              key={ item.value }
              label={ item.label }
              value={ item.value }
            />
          ))
        }
      </CustomTabs>
      {
        currentTab === NOTES_TAB_VALUE &&
        <Tooltip
          arrow={ true }
          disabled={ !isAddButtonDisabled }
          title={ disableTooltipText }
        >
          <AddButtonWrapper>
            <AddNoteButton
              disabled={ isAddButtonDisabled }
              onClick={ onAddNoteClick }
              withButtonTitle={ false }
            />
          </AddButtonWrapper>
        </Tooltip>
      }
    </Wrapper>
  );
});
