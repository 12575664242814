import React, { memo, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { useDidUpdate } from '@customHooks';

import { NoNoteDataViewImage } from '@/assets';
import NoDataItemsView from '@modules/NoDataItemsView';
import Tooltip from '@components/Tooltip';

import { AddNoteButton } from '@modules/NotesAndHistory/components';
import {
  GeneralFilters,
  Header,
  NoteItem,
  StandardFilters
} from '@modules/NotesAndHistory/components/Notes/components';

import { AddButtonWrapper, NoDataSignature, NoDataWrapper, NoteItemsWrapper, Wrapper } from './styles';

import { Item } from '@/components/RowFilter/types';
import { NotesViewProps, Notes } from './types';
import { GeneralFiltersValue } from '../GeneralFilters/types';
import { NOTE_AND_HISTORY_TYPE } from '@/modules/NotesAndHistory/data';
import { useLazyLoading } from '@/shared/customHooks/useLazyLoading';

const ALL_LABEL = 'All';

export const NotesView = memo(({
  control,
  creationDateFieldName,
  disableAddNoteTooltipText,
  instanceStore,
  isAbleToEdit,
  isAddNoteBtnDisabled,
  isShowRowWithTitleAndAddButton,
  noteAndHistoryType,
  notes,
  notesName,
  notesProcessor,
  onAddNoteClick,
  onDeleteClick,
  onEditClick,
  title,
}: NotesViewProps) => {
  const {
    filters,
    increaseCurrentPage,
    searchState,
    setSearchState
  } = instanceStore;

  // need to get actual state
  const currentStateNotes = useWatch({
    control: control,
    name: notesName
  });

  const [onScroll, containerRef] = useLazyLoading<HTMLDivElement>({
    onIntersection: increaseCurrentPage,
    marginFromBottom: 10,
    delay: 100
  });

  const [ filterState, setFilterState ] = useState<any>(
    noteAndHistoryType === NOTE_AND_HISTORY_TYPE.module ?
      null : (filters || null));
  const [ notesToRender, setNotesToRender ] = useState<Notes>(() => {
    return notesProcessor(creationDateFieldName, filterState, notes) as Notes;
  });

  useDidUpdate(() => {
    notesProcessor(creationDateFieldName, filterState, notes, setNotesToRender);
  }, [filterState, notes, currentStateNotes, creationDateFieldName]);

  const noResultViewSignature = useMemo(() => (
    <>
      <NoDataSignature >No results were found </NoDataSignature> 
      for your request
    </>
  ), []);

  const tooltipTitle = useMemo(() => (
    isAddNoteBtnDisabled ? disableAddNoteTooltipText : ''
  ), [isAddNoteBtnDisabled]);

  
  return (
    <Wrapper>
      <Header>
        {
          isShowRowWithTitleAndAddButton &&
          <Header.Row>
            <Header.Tiltle>
              { title }
            </Header.Tiltle>
            { isAbleToEdit &&
              <Tooltip
                arrow={ true }
                disabled={ !isAddNoteBtnDisabled }
                title={ tooltipTitle }
              >
                <AddButtonWrapper>
                  <AddNoteButton
                    disabled={ isAddNoteBtnDisabled }
                    onClick={ onAddNoteClick }
                    withButtonTitle={ false }
                  />
                </AddButtonWrapper>
              </Tooltip>
            }
          </Header.Row>
        }
        <Header.Row>
          { noteAndHistoryType === NOTE_AND_HISTORY_TYPE.module ?
            <StandardFilters
              onChange={ (value: Item) => {
                setFilterState(value);
                setSearchState(value !== ALL_LABEL);
              } }
            /> :
            <GeneralFilters
              instanceStore={ instanceStore }
              numberOfCurrentStateNotes={ currentStateNotes ? currentStateNotes.length : 0 }
              numberOfNotesToRender={ notesToRender.length }
              onChange={ (value: GeneralFiltersValue) => setFilterState(value) }
            />
          }
        </Header.Row>
      </Header>

      <NoteItemsWrapper
        onScroll={ onScroll }
        ref={ containerRef }
      >
        {
          notesToRender.length > 0 && notesToRender.map((note, index) => (
            <NoteItem
              // TODO: TS migtrate
              // @ts-ignore
              isAbleToEdit={ isAbleToEdit }
              item={ note }
              key={ note?.useFormNoteId || index }
              onDeleteClick={ onDeleteClick(note) }
              onEditClick={ onEditClick(note) }
            />
          ))
        }
      </NoteItemsWrapper>

      { notesToRender.length === 0 &&
        <NoDataWrapper>
          { (!currentStateNotes || currentStateNotes.length === 0) && !searchState ?
            <NoDataItemsView
              $imageWidth={ '100%' }
              $infoTextFontSize={ '20px' }
              markedText={ 'Notes' }
            /> :
            <NoDataItemsView
              $infoTextFontSize={ '20px' }
              imgSrc={ searchState && NoNoteDataViewImage }
              //@ts-ignore TODO migrate to TS
              signature={ noResultViewSignature }
            />
          }
        </NoDataWrapper>
      }
    </Wrapper>
  );
});

NotesView.displayName = 'NotesView';
