import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';
import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';

import { CONTACT_NOTES_AND_HISTORY_INPUT_NAMES } from '@constants/contactNote';

import NotesAndHistory, { NOTE_AND_HISTORY_TYPE } from '@modules/NotesAndHistory';

import { NotesAndHistoryWrapper } from './styles';


export const Notes = observer(() => {
  const store = useContactDetailsSideBarPanelLocalStore();
  const { getNotes, predefinedTags, notesAndHistoryStore } = store;

  const methods = useFormContext();
  const { profile } = useStore().SettingsStore;

  useEffect(() => {
    getNotes();
    return () => {
      notesAndHistoryStore.resetState();
      notesAndHistoryStore.resetStore();
    };
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotesAndHistoryWrapper>
      {/* TODO: note refactoring */}
      {/* @ts-ignore */}
      <NotesAndHistory
        control={ methods.control }
        currentUser={ profile }
        instanceStore={ notesAndHistoryStore }
        isAbleToEdit={ false }
        isAddNoteBtnDisabled={ false }
        linkedContactData={ null }
        names={ CONTACT_NOTES_AND_HISTORY_INPUT_NAMES }
        noteAndHistoryType={ NOTE_AND_HISTORY_TYPE.general }
        predefinedTags={ predefinedTags }
        title={ 'Notes & History Timeline ' }
      />
    </NotesAndHistoryWrapper>
  );
});
