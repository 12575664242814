import ApiService from '@services/api/api.service';

export const TO_DOS_URLS = {
  getToDos: 'api/task/getForGrid',
  getContactsTodos: 'api/task/getGridForContact',
  getOtherTodos: 'api/task/getForGridOtherTask',
  getGridForContact: 'api/task/getGridContact',
  massFlagUpdate: 'api/task/updateFlags',
  updateFromGrid: 'api/task/updateGrid',
};

const getTodos = (params) => {
  return ApiService.get(TO_DOS_URLS.getToDos, { params });
};

const getContactsTodos = (params) => {
  return ApiService.get(TO_DOS_URLS.getContactsTodos, { params });
};

const getOtherTodos = (params) => {
  return ApiService.get(TO_DOS_URLS.getOtherTodos, { params });
};

const getGridForContact = (params) => {
  return ApiService.get(TO_DOS_URLS.getGridForContact, { params });
};

const massFlagUpdate = (data) => {
  return ApiService.patch(TO_DOS_URLS.massFlagUpdate, data);
};

const updateFromGrid = (data) => {
  return ApiService.patch(TO_DOS_URLS.updateFromGrid, data);
};

export {
  getContactsTodos,
  getGridForContact,
  getOtherTodos,
  getTodos,
  massFlagUpdate,
  updateFromGrid,
};
