import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';
import { useStore } from '@store';

import { getContactName } from '@/shared/utils/getContactName';
import { MODAL_TYPE } from '@constants/modalTypes';

import { MainInformation, ContactDetails as ContactDetailsComponent } from '@modules/ContactComponents';
import LimitAccessPopup from '@modules/LimitAccessPopup';

import { Content } from './styles';

export const ContactDetails = observer(() => {
  const store = useContactDetailsSideBarPanelLocalStore();
  const {
    adviserData,
    contact,
    contactsDetails,
    contactSpouse,
    init,
  } = store;

  const modalStore = useStore().ModalStore;

  const methods = useFormContext();

  const isBlocked = typeof contactSpouse?.editAllow === 'number' && !contactSpouse?.editAllow;

  const onSpouseClick = useCallback(() => {
    if(contactSpouse) {
      if(isBlocked) {
        modalStore.openModal({
          modalType: MODAL_TYPE.CONTACT_LIMIT_ACCESS,
          component: LimitAccessPopup,
          modalProps: {
            withPushToContacts: false
          }
        });
      } else {
        init(contactSpouse.id, methods);
      }
    }
  }, [init, contactSpouse, modalStore]);

  if(!contact) {
    return null;
  }

  return (
    <Content>
      <MainInformation
        adviserData={ adviserData }
        contact={ contact }
        contactSpouse={ contactSpouse }
        isBlocked={ isBlocked }
        isGlobalDisabled={ true }
        onSpouseClick={ onSpouseClick }
        renderHeaderTitle={ getContactName(contact) }
      />
      <ContactDetailsComponent
        contact={ contact }
        contactsDetails={ contactsDetails }
        headerTitle={ 'Contact Information' }
      />
    </Content>
  );
});
