import ApiService from '@services/api/api.service';
// TODO migrate to typescipt

export const ACCOUNT_AND_PLANS_URLS = {
  getAccountAndPlans: 'api/accountPlans/getGrid',
  getContactsAccountAndPlans: 'api/accountPlans/getGridForContact',
  updateFlags: 'api/accountPlans/updateFlags',
  deleteAccountAndPlans: 'api/accountPlans/delete',
  getAccountAndPlansPopupSettings: 'api/setting/accountPlans/getPopupSetting',
  saveAccountOrPoliceOrPlan: 'api/accountPlans/create',
  getByIdAccountAndPlans: 'api/accountPlans/getById',
  updateAccountAndPlans: 'api/accountPlans/update',
};

export const getAccountAndPlans = (params) => {
  return ApiService.get(ACCOUNT_AND_PLANS_URLS.getAccountAndPlans, { params });
};

export const getContactsAccountAndPlans = (params) => {
  return ApiService.get(ACCOUNT_AND_PLANS_URLS.getContactsAccountAndPlans, { params });
};

export const updateFlags = (data) => {
  return ApiService.patch(ACCOUNT_AND_PLANS_URLS.updateFlags, data);
};

export const deleteAccountAndPlans = (params) => {
  return ApiService.delete(ACCOUNT_AND_PLANS_URLS.deleteAccountAndPlans, { params });
};

export const getAccountAndPlansPopupSettings = (params) => {
  return ApiService.get(ACCOUNT_AND_PLANS_URLS.getAccountAndPlansPopupSettings, { params });
};

export const saveAccountOrPoliceOrPlan = (data) => {
  return ApiService.post(ACCOUNT_AND_PLANS_URLS.saveAccountOrPoliceOrPlan, data);
};

export const getByIdAccountAndPlans = (params) => {
  return ApiService.get(ACCOUNT_AND_PLANS_URLS.getByIdAccountAndPlans, { params });
};

export const updateAccountAndPlans = (data) => {
  return ApiService.patch(ACCOUNT_AND_PLANS_URLS.updateAccountAndPlans, data);
};