import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { useContactDetailsSideBarPanelLocalStore } from '@modules/ContactDetailsSideBarPanel/store';

import { PendingTasks } from '@modules/ContactComponents';


export const ToDosTable = observer(() => {
  const store = useContactDetailsSideBarPanelLocalStore();
  const {
    getToDos,
    isLoading,
    onRemove,
    onUpdate,
    setTodoTableActivePage,
    table,
  } = store;

  useEffect(() => {
    getToDos();
  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <PendingTasks
      $tableHeight={ 'calc(100vh - 190px)' }
      headerTitle={ 'To-Dos' }
      isLoading={ isLoading }
      isShowViewAllButton={ false }
      isShowAddTaskButton={ false }
      onDeleteTask={ onRemove }
      onScrollToBottomAction={ setTodoTableActivePage }
      onUpdateTask={ onUpdate }
      table={ table.items }
    />
  );
});
