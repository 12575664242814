import React from 'react';
import MuiTable from '@material-ui/core/Table';

import { useDidUpdate } from '@customHooks';
import { useLazyLoading } from '@/shared/customHooks/useLazyLoading';

import { ContainerProps } from './types';

import { StyledTableContainer } from './styles';


export const Container = ({
  $height,
  ContainerProps,
  TableProps,
  children,
  onScrollToBottomAction = () => {},
  triggerScrollToTop,
}: ContainerProps) => {

  const [onScroll, containerRef] = useLazyLoading<HTMLDivElement>({
    onIntersection: onScrollToBottomAction,
    delay: 0
  });

  useDidUpdate(() => {
    if(containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [triggerScrollToTop]);

  return (
    <StyledTableContainer
      $height={ $height }
      onScroll={ onScroll }
      ref={ containerRef }
      { ...ContainerProps }
    >
      <MuiTable
        aria-label="sticky table"
        stickyHeader
        style={ { tableLayout: 'fixed' } }
        { ...TableProps }
      >
        { children }
      </MuiTable>
    </StyledTableContainer>
  );
};
