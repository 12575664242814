import { endOfDay, isAfter, isBefore } from 'date-fns';

import { FORM_NAMES } from '../components/Notes/components/GeneralFilters/data';

// TODO: note refactoring
export const getIsDifferFromFilters = (filters: any, note: any) => {
  const result = Object.entries(filters).some((name) => {
    const [ key, value ] = name;
    if(key === FORM_NAMES.noteType && value) {
      return value !== note.noteType;
    }
    if(key === FORM_NAMES.noteEntity && value) {
      const linkedContactType = note.linkedItem?.linkedContactType;
      return value !== linkedContactType;
    }
    if(key === FORM_NAMES.startDate && value) {
      const result = isAfter(new Date(note.created), new Date(value as string));
      return !result;
    }
    if(key === FORM_NAMES.endDate && value) {
      const result = isBefore(new Date(note.created), endOfDay(new Date(value as string)));
      return !result;
    }
    if(key === FORM_NAMES.noteTitleContent && value) {
      //@ts-ignore
      const result = value.some((text) => {
        const newText = text.trim();
        const noteTextResult = note.noteText?.toUpperCase().indexOf(newText.toUpperCase());
        const noteTitleResult = note.title?.toUpperCase().indexOf(newText.toUpperCase());
        return !noteTextResult || !noteTitleResult;
      });
      return !result;
    }
    if(key === FORM_NAMES.noteTagValue && value) {
      //@ts-ignore
      const result = value.some((text) => {
        const newText = text.trim();
        const result = note?.tags?.some((tag: any) => {
          return tag.value.toUpperCase().indexOf(newText.toUpperCase()) >= 0;
        });
        return result;
      });
      return !result;
    }
  });

  return result;
};