import { action, makeAutoObservable } from 'mobx';

import { Store } from '@services/store/store';

import { LinkedContact } from '@/shared/types/linkedContact';

class AppHeaderStore {
  contact: LinkedContact | null = null;
  coreStore: Store;
  isLoad: boolean = false;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      resetStore: action.bound,
      setContact: action.bound,
    });

    this.coreStore = coreStore;
  }

  setContact(contact: LinkedContact | null) {
    this.contact = contact;
  }

  setIsLoad(state: boolean) {
    this.isLoad = state;
  }

  resetStore() {
    this.contact = null;
  }
}

export default AppHeaderStore;
