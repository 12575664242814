import { cloneDeep } from 'lodash';

import { FORM_NAMES } from '../components/Notes/components/GeneralFilters/data';
import { NOTE_TYPES, SUB_CATEGORIES_COMMUNICATION_TYPES } from '../data';

// TODO: note refactoring
export const getPreparedFilters = (filters: any) => {
  const newFilters = cloneDeep(filters);
  if(newFilters[FORM_NAMES.noteEntity] && newFilters[FORM_NAMES.noteEntity] === NOTE_TYPES.all) {
    newFilters[FORM_NAMES.noteEntity] = null;
  }
  if(newFilters[FORM_NAMES.noteType] && newFilters[FORM_NAMES.noteType] === SUB_CATEGORIES_COMMUNICATION_TYPES.all) {
    newFilters[FORM_NAMES.noteType] = null;
  }

  return newFilters;
};
