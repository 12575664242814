import { ColumnType } from '@/components/Table/components/Header/types';

export const TRASH_TABLE_COLUMNS_IDS = {
  accountType: 'accountType',
  amount: 'amount',
  category: 'category',
  contactName: 'contactName',
  createdAt: 'createdAt',
  deletedBy: 'deletedBy',
  deleteStatus: 'deleteStatus',
  fullName: 'fullName',
  name: 'name',
  opportunityStatus: 'opportunityStatus',
  salesCycleStatus: 'salesCycleStatus',
  saleType: 'saleType',
  stage: 'stage',
  type: 'type',
  typeList: 'typeList',
};

const NAME_COLUMN_STYLE = {
  style: {
    width: '22%'
  },
};

const NAME_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.name,
  label: 'Name',
  sortable: true,
  ...NAME_COLUMN_STYLE
};

const LIST_NAME_COLUMN: ColumnType = {
  ...NAME_COLUMN,
  style: {
    width: '20%'
  },
  label: 'List Name',
};

const CONTACT_NAME_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.contactName,
  label: 'Name',
  sortable: true,
  ...NAME_COLUMN_STYLE
};

const TYPE_COLUMN: Omit<ColumnType, 'label'> = {
  id: TRASH_TABLE_COLUMNS_IDS.type,
  style: {
    width: '14%'
  },
  sortable: true,
  separator: true,
};

const CONTACT_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  label: 'Contact Type',
};

const LIST_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  style: {
    width: '9%'
  },
  id: TRASH_TABLE_COLUMNS_IDS.typeList,
  label: 'Type',
};

const ACTIVITIES_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  label: 'Activity Type',
};

const TASKS_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  id: TRASH_TABLE_COLUMNS_IDS.category,
  label: 'Tasks Type',
};

const ACCOUNTS_AND_PLANS_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  id: TRASH_TABLE_COLUMNS_IDS.accountType,
  label: 'Type',
};

const CLIENT_OPPORTUNITIES_TYPE_COLUMN: ColumnType = {
  ...TYPE_COLUMN,
  id: TRASH_TABLE_COLUMNS_IDS.saleType,
  label: 'Sale Type',
};

const SALES_CYCLE_STATUS_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.salesCycleStatus,
  label: 'Sales Cycle Status',
  style: {
    width: '14%'
  },
  sortable: true,
  separator: true,
};

const SALES_CYCLE_STAGE_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.stage,
  label: 'Stage',
  style: {
    width: '14%'
  },
  sortable: true,
  separator: true,
};

const SALES_CYCLES_AND_CLIENT_OPPORTUNITIES_AMOUNT_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.amount,
  label: 'Amount ($)',
  style: {
    width: '14%'
  },
  sortable: true,
  separator: true,
};

const CLIENT_OPPORTUNITIES_STATUS_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.opportunityStatus,
  label: 'Opportunity Status',
  style: {
    width: '14%'
  },
  sortable: true,
  separator: true,
};

const DELETE_BY_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.deletedBy,
  label: 'Deleted By',
  style: {
    width: '17%'
  },
  sortable: true,
  separator: true,
};

const DELETE_DATE_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.createdAt,
  label: 'Deleted Date',
  style: {
    width: '17%'
  },
  sortable: true,
  separator: true,
};

const DELETE_STATUS_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.deleteStatus,
  label: 'Deletion Status ',
  style: {
    width: '17%'
  },
  separator: true,
};

const LINKED_CONTACT_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.contactName,
  label: 'Linked Contact',
  style: {
    width: '19%'
  },
  sortable: true,
  separator: true,
};

const LISTS_LINKED_CONTACT_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.contactName,
  label: 'Linked Contacts',
  style: {
    width: '24%'
  },
  separator: true,
};

const SALES_CYCLES_LINKED_CONTACT_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.fullName,
  label: 'Linked Contact',
  style: {
    width: '20%'
  },
  sortable: true
};

const CLIENT_OPPORTUNITIES_LINKED_CONTACT_COLUMN: ColumnType = {
  id: TRASH_TABLE_COLUMNS_IDS.fullName,
  label: 'Linked Contact',
  style: {
    width: '20%'
  },
  sortable: true
};

export const CONTACTS_COLUMNS = [
  CONTACT_NAME_COLUMN,
  CONTACT_TYPE_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const LISTS_COLUMNS = [
  LIST_NAME_COLUMN,
  LIST_TYPE_COLUMN,
  LISTS_LINKED_CONTACT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const ACTIVITIES_COLUMNS = [
  NAME_COLUMN,
  ACTIVITIES_TYPE_COLUMN,
  LINKED_CONTACT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const TASKS_COLUMNS = [
  NAME_COLUMN,
  TASKS_TYPE_COLUMN,
  LINKED_CONTACT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const SALES_CYCLES_COLUMNS = [
  SALES_CYCLES_LINKED_CONTACT_COLUMN,
  SALES_CYCLE_STATUS_COLUMN,
  SALES_CYCLE_STAGE_COLUMN,
  SALES_CYCLES_AND_CLIENT_OPPORTUNITIES_AMOUNT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const ACCOUNTS_AND_PLANS_COLUMNS = [
  NAME_COLUMN,
  ACCOUNTS_AND_PLANS_TYPE_COLUMN,
  LINKED_CONTACT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];

export const CLIENT_OPPORTUNITIES_COLUMNS = [
  CLIENT_OPPORTUNITIES_LINKED_CONTACT_COLUMN,
  CLIENT_OPPORTUNITIES_STATUS_COLUMN,
  CLIENT_OPPORTUNITIES_TYPE_COLUMN,
  SALES_CYCLES_AND_CLIENT_OPPORTUNITIES_AMOUNT_COLUMN,
  DELETE_BY_COLUMN,
  DELETE_DATE_COLUMN,
  DELETE_STATUS_COLUMN,
];
