import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-right: 20px;
  padding-bottom: 15px;
  
  &:after {
    position: absolute;
    content: '';
    width: calc(100% - 20px);
    bottom: 0;
    height: 1px;
    background-color: ${COLORS.greyLight};
  }
`;
export const HeaderTitle = styled.div`
  margin-right: auto;
  font-size: 16px;
  color: ${COLORS.primaryText};
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 15px;
  
  &:last-child{
    margin-bottom: 0;
  }
`;