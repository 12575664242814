import { GeneralFiltersValue } from './types';

export const SEARCH_BUTTON_MODE = {
  active: 'active',
  inactive: 'inactive',
  withResult: 'withResult',
} as const;

export const ROW_FILTER_ITEMS_NAME = {
  notes: 'Notes',
  communications: 'Communications',
  tags: 'Tags'
} as const;

export const ROW_FILTER_ITEMS = [
  ROW_FILTER_ITEMS_NAME.notes,
  ROW_FILTER_ITEMS_NAME.communications
];

export const FILTER_OPTIONS = [{
  value: ROW_FILTER_ITEMS_NAME.notes, 
  label: ROW_FILTER_ITEMS_NAME.notes,
}, {
  value: ROW_FILTER_ITEMS_NAME.tags,
  label: ROW_FILTER_ITEMS_NAME.tags,
}];

// TODO: note refactoring
export const FORM_NAMES = {
  endDate: 'endDate',
  noteEntity: 'noteEntity',
  noteTagValue: 'noteTagValue',
  noteTitleContent: 'noteTitleContent',
  noteType: 'noteType',
  selectFilter: 'selectFilter',
  startDate: 'startDate',
} as const;

export const FORM_DEFAULT_FIRST_LEVEL_FILTERS_VALUES = {
  [FORM_NAMES.noteEntity]: '',
  [FORM_NAMES.noteType]: '',
};

export const FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES = {
  [FORM_NAMES.startDate]: '',
  [FORM_NAMES.endDate]: '',
  [FORM_NAMES.noteTitleContent]: null,
  [FORM_NAMES.noteTagValue]: null,
  [FORM_NAMES.selectFilter]: ROW_FILTER_ITEMS_NAME.notes,
};

// TODO: note refactoring
export const FORM_DEFAULT_FILTERS_VALUES: any = {
  ...FORM_DEFAULT_FIRST_LEVEL_FILTERS_VALUES,
  ...FORM_DEFAULT_SEARCH_PANEL_FILTERS_VALUES,
};
