import { Items, ObjectLike } from '@/shared/types/commonTypes';
import ApiService from '@services/api/api.service';

export const TRASH_URLS = {
  getContactsForTrash: 'api/crm/contact/getTrashContactGrid',
  getListsForTrash: 'api/list/getListTrashGrid',
  getActivitiesForTrash: 'api/activity/getActivityTrashGrid',
  getAccountsAndPlansForTrash: 'api/accountPlans/getTrashContactGrid',
  getTasksForTrash: 'api/task/getTaskTrashGrid',
  getSalesCyclesForTrash: 'api/pipeline/getTrashSalesPipeLineGrid',
  getClientOpportunitiesForTrash: 'api/opportunity/getTrashOpportunityGrid',
  deleteContactsFromTrash: 'api/crm/contact/permanentDelete',
  deleteListsFromTrash: 'api/list/permanentDelete',
  deleteActivitiesFromTrash: 'api/activity/permanentDelete',
  deleteAccountAndPlansFromTrash: 'api/accountPlans/deletePermanently',
  deleteTasksFromTrash: 'api/task/deletePermanently',
  deleteSalesCyclesFromTrash: 'api/pipeline/deletePermanently',
  deleteClientOpportunitiesFromTrash: 'api/opportunity/deletePermanently',
  restoreContactsFromTrash: 'api/crm/contact/restore',
  restoreListsFromTrash: 'api/list/restore',
  restoreActivitiesFromTrash: 'api/activity/restore',
  restoreAccountAndPlansFromTrash: 'api/accountPlans/restore',
  restoreTasksFromTrash: 'api/task/restore',
  restoreSalesCyclesFromTrash: 'api/pipeline/restore',
  restoreClientOpportunitiesFromTrash: 'api/opportunity/restore'
};

export const getContactsForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getContactsForTrash, { params });
};

export const getListsForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getListsForTrash, { params });
};

export const getActivitiesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getActivitiesForTrash, { params });
};

export const getAccountsAndPlansForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getAccountsAndPlansForTrash, { params });
};

export const getTasksForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getTasksForTrash, { params });
};

export const getSalesCyclesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getSalesCyclesForTrash, { params });
};

export const getClientOpportunitiesForTrash = (params: ObjectLike<string | number>) => {
  return ApiService.get(TRASH_URLS.getClientOpportunitiesForTrash, { params });
};

export const deleteContactsFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteContactsFromTrash, { params });
};

export const deleteListsFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteListsFromTrash, { params });
};

export const deleteActivitiesFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteActivitiesFromTrash, { params });
};

export const deleteAccountAndPlansFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteAccountAndPlansFromTrash, { params });
};

export const deleteTasksFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteTasksFromTrash, { params });
};

export const deleteSalesCyclesFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteSalesCyclesFromTrash, { params });
};

export const deleteClientOpportunitiesFromTrash = (params: Items) => {
  return ApiService.delete(TRASH_URLS.deleteClientOpportunitiesFromTrash, { params });
};

export const restoreContactsFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreContactsFromTrash, {}, { params });
};

export const restoreListsFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreListsFromTrash, {}, { params });
};

export const restoreActivitiesFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreActivitiesFromTrash, {}, { params });
};

export const restoreAccountAndPlansFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreAccountAndPlansFromTrash, {}, { params });
};

export const restoreTasksFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreTasksFromTrash, {}, { params });
};

export const restoreSalesCyclesFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreSalesCyclesFromTrash, {}, { params });
};

export const restoreClientOpportunitiesFromTrash = (params: Items) => {
  return ApiService.patch(TRASH_URLS.restoreClientOpportunitiesFromTrash, {}, { params });
};
