import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '@store';

import { PlusWithCircle, Trash } from '@/assets';

import BlockButton from '@components/BlockButton';
import BubbleButton from '@components/BubbleButton';
import PreTableWrapper from '@components/BasicLayoutComponents/PreTableWrapper';
import RowFilter from '@components/RowFilter';
import ViewSwitcher  from '@/components/ViewSwitcher';
import { UserSelectFilter } from '@modules/UserSelectFilter';

import { ActionsWrapper, AddWrapper, BubbleFiltersWrapper, ViewSwitcherWrapper } from './styles';

import { FILTER_ALL_BTN_LABEL, VIEW_TYPES } from '@constants/common';
import {
  SALES_ACTIVE_CYCLE_FIELD_NAMES,
  SALES_ACTIVE_CYCLE_FILTER_NAMES,
  SALES_ACTIVE_CYCLE_STAGES
} from '@constants/salesActiveCycle';

import { SalesActiveTablePreHeaderProps } from './types';
import { salesCycleTabs } from '@constants/routes/salesCycle';

export const SalesActivePreHeader = observer(({
  onAddClick,
  onDelete,
}: SalesActiveTablePreHeaderProps) => {
  const {
    isLoad,
    currentTab,
    changeView,
    currentView,
    nestedStore
  } = useStore().SalesCycleStore;

  const isSalesCycleTable =
    currentTab === salesCycleTabs.activeSalesCycles &&
    currentView === VIEW_TYPES.table &&
    'table' in nestedStore;

  const onAddClickWrapper = useCallback(() => onAddClick(),[onAddClick]);

  const onDeleteWrapper = useCallback(() => {
    if(isSalesCycleTable){
      const data = nestedStore.table.selectedIDs as Array<number>;
      onDelete(data);
    }
  }, [isSalesCycleTable, nestedStore, onDelete]);

  const onStageChange = useCallback((value: any) => {
    if('setStageFilter' in nestedStore){
      nestedStore.setStageFilter(value === FILTER_ALL_BTN_LABEL ? null : value);
    }
  }, [nestedStore]);

  const onUserFilterChange = useCallback((value: Array<string>) => {
    nestedStore.setFilterState(SALES_ACTIVE_CYCLE_FILTER_NAMES.officePrimaryAdvisor, value);
  }, [nestedStore]);

  const isActive = 'table' in nestedStore ? nestedStore.table.globalFlagged : nestedStore.globalFlagged;

  const onStateChange = useCallback((state: boolean) => {
    if('table' in nestedStore) {
      nestedStore.table.setGlobalFlaggedFilters(state);
    } else {
      nestedStore.setGlobalFlaggedFilters(state);
    }
  }, [nestedStore]);

  return (
    <PreTableWrapper
      bookmarkIconProps={ {
        isActive,
        onStateChange
      } }
    >
      <UserSelectFilter
        closeTrigger={ isLoad }
        onChange={ onUserFilterChange }
        usersOptions={ 'filterData' in nestedStore ? nestedStore.filterData?.officePrimaryAdvisor : [] }
        value={ 'filters' in nestedStore ? nestedStore?.filters?.officePrimaryAdvisor : []  }
        withAllUserOption={ true }
      />

      <ViewSwitcherWrapper>
        <ViewSwitcher
          currentValue={ currentView }
          onChange={ changeView }
        />
      </ViewSwitcherWrapper>
      {
        isSalesCycleTable &&
        <BubbleFiltersWrapper>
          <RowFilter
            allButtonLabel={ FILTER_ALL_BTN_LABEL }
            initState={
              'stageFilter' in nestedStore
                ? nestedStore.stageFilter?.[SALES_ACTIVE_CYCLE_FIELD_NAMES.stage]
                : null
            }
            items={ Object.values(SALES_ACTIVE_CYCLE_STAGES) }
            limit={ 6 }
            onChange={ onStageChange }
            renderItem={ ({
              content,
              isSelected,
              isShowMore,
              onClick,
              isSelectedFromMore,
              indexForKey,
            }) => (
              <BubbleButton
                $isActive={ isSelected && !isShowMore }
                $isSecondActiveStyle={ (isSelected && isShowMore) || isSelectedFromMore }
                key={ indexForKey }
                onClick={ onClick }
              >
                { content }
              </BubbleButton>
            ) }
          />
        </BubbleFiltersWrapper>
      }
      <ActionsWrapper>
        {
          isSalesCycleTable && nestedStore.table.selectedIDs.length > 0 &&
          <BlockButton
            onClick={ onDeleteWrapper }
            startIcon={ <Trash/> }
          >
            Delete All Selected
          </BlockButton>
        }
        <AddWrapper>
          <BlockButton
            $iconSize={ '16px' }
            onClick={ onAddClickWrapper }
            startIcon={ <PlusWithCircle/> }
          >
            Add Cycle
          </BlockButton>
        </AddWrapper>
      </ActionsWrapper>
    </PreTableWrapper>
  );
});
