import { action, makeAutoObservable, flow } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { Store } from '@services/store/store';
import { getViewedContact } from '@services/api/common/common';

import { MODAL_TYPE } from '@constants/modalTypes';
import { WEBSOCKET_MESSAGE_TYPES } from '@/shared/constants/common';

import SalesActiveCycleKanbanNotification from '@modules/SalesPipline/SalesActiveCycleKanbanNotification';

import {
  LastViewedContactsResponse,
  WebsocketData,
  WebsocketEventData,
  WebsocketMessageData,
  WebsocketLoginSettings
} from './types';
import { LastViewedContacts } from '@/shared/types/commonTypes';
import { LinkedContact } from '@/shared/types/linkedContact';

class AppStore {
  coreStore: Store;
  isLoad: boolean = false;
  lastViewedContacts: LastViewedContacts = [];
  websocket: WebSocket | null = null;
  websocketChannelName: string | null = null;
  websocketURL: string | null = null;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      getRecentContacts: flow.bound,
      init: action.bound,
      resetStore: action.bound,
    });

    makePersistable(this, {
      name: 'websocketData',
      properties: ['websocketChannelName', 'websocketURL'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.coreStore = coreStore;
  }

  init() {
    this.connectWebsocket();
    this.getRecentContacts();
  }

  *getRecentContacts() {
    const profileId = this.coreStore.SettingsStore.profileId;
    if(profileId) {
      this.setIsLoad(true);
      try {
        const resp: LastViewedContactsResponse = yield getViewedContact();
        this.lastViewedContacts = resp.data.data;
      } catch(error) {
        console.log(error);
      } finally {
        this.setIsLoad(false);
      }
    }
  }

  connectWebsocket() {
    if(this.websocketChannelName && this.websocketURL) {
      this.setIsLoad(true);
      try {
        this.websocket = new WebSocket(this.websocketURL);
        this.websocket.onopen = this.onWebsocketOpen.bind(this);
        this.websocket.onmessage = this.onWebsocketMessage.bind(this);
      } catch (error) {
        console.log(error);
      } finally {
        this.setIsLoad(false);
      }
    }
  }

  onWebsocketOpen() {
    const payload = {
      event: 'pusher:subscribe',
      data: {
        channel: this.websocketChannelName
      }
    };
    this.websocket?.send(JSON.stringify(payload));
  }

  onWebsocketMessage(event: MessageEvent<string>) {
    try {
      const eventData: WebsocketEventData = JSON.parse(event?.data);

      if(eventData?.data){
        const data: WebsocketData = JSON.parse(eventData?.data as string);

        if(data?.message){
          console.log('message', data?.message);
          const message: WebsocketMessageData = JSON.parse(data?.message as string);
          if(message.messageType === WEBSOCKET_MESSAGE_TYPES.updateContactsList) {
            this.getRecentContacts();
          }
          if(message.messageType === WEBSOCKET_MESSAGE_TYPES.updateKanban) {
            if(this.coreStore.SalesCycleStore.SalesActiveCycleKanban.isPageActive) {
              this.coreStore.SalesCycleStore.SalesActiveCycleKanban.websocketNotificationCallback = () => {
                this.coreStore.ModalStore.openModal({
                  modalType: MODAL_TYPE.KANBAN_IS_UPDATED,
                  component: SalesActiveCycleKanbanNotification,
                  modalProps: {
                    action: this.coreStore.SalesCycleStore.SalesActiveCycleKanban.getKanbanSalesCyclesWithLoad
                  }
                });
              };
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  setWebsocketData(settings: WebsocketLoginSettings) {
    this.websocketChannelName = settings.websockets.channelName;
    this.websocketURL = settings.websockets.url;  
  }

  setLastViewedContactsList(contacts: Array<LinkedContact>) {
    this.lastViewedContacts = contacts;
  }

  setIsLoad(state: boolean) {
    this.isLoad = state;
  }

  resetStore() {
    if(this.websocket) {
      this.websocket.close();
      this.websocket = null;
      this.websocketChannelName = null;
      this.websocketURL = null;
      this.lastViewedContacts = [];
    }
  }
}

export default AppStore;
