import { makeAutoObservable, flow, reaction, action } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import debounce from 'lodash/debounce';

import { CALENDAR_VIEWS, ENTITY_NAMES } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';

import { getCalendarList, getFilterOptions } from '@services/api/calendar/calendar';

import { calendarNormalizer } from '@services/store/calendarStore/normalizers/calendarNormalizer';
import { filterOptionsNormalizer } from '@services/store/calendarStore/normalizers/filterOptionsNormalizer';
import { isDateInRange } from '@services/store/calendarStore/utils';

import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import {
  getFiltersCount,
  getFilterParams,
  setWhereNameAndOrderNameFilterParams,
} from '@utils/filterUtils';

import { getMonthRangeWithIndents } from '@services/store/calendarStore/utils';
import {
  updateActivity,
  saveActivity,
  deleteActivityToTrash
} from '@services/api/addAndEditActivity/addAndEditActivity';


class CalendarStore {
  associatedUsers = [];
  currentView = CALENDAR_VIEWS.month;
  events = [];
  filters = {};
  filtersData = {};
  isCalendarLoad = false;
  isFiltersOpen = false;
  date = null;
  month = null;
  userSelectedDate = null;
  asyncRequestExecutor;
  notificationHelper;
  pageActive = false;
  showMoreAnchorElem = null;
  showMoreEvents = null;

  constructor(coreStore) {
    makeAutoObservable(this, {
      deleteActivity: flow.bound,
      initPage: flow,
      onClear: action.bound,
      resetShowMoreData: action.bound,
      saveOrUpdateActivity: flow.bound,
      setShowMoreAnchorElem: action.bound,
      setShowMoreEvents: action.bound,
    });

    this.coreStore = coreStore;

    makePersistable(this, {
      name: 'calendar',
      properties: ['currentView'],
      storage: localStorage,
    }, {
      fireImmediately: false
    });

    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.activity
    );

    this.asyncRequestExecutor = new AsyncRequestExecutor();

    this.onDateChange = this.createOnDateChange();
    this.onFiltersChange = this.createOnFiltersChangeReaction();
  }

  *saveOrUpdateActivity(data){
    try {
      this.isCalendarLoad = true;

      if(data?.id) {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => updateActivity(data),
          onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success })
        });
      } else {
        yield this.asyncRequestExecutor.wrapAsyncOperation({
          func: () => saveActivity(data),
          onError: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.error }),
          onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success })
        });
      }

      yield this.getSettings();
      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      this.isCalendarLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *deleteActivity(data){
    try {
      this.isCalendarLoad = true;
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteActivityToTrash(data),
        onError: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.remove({ status: NOTIFICATION_TYPES.success })
      });

      yield this.getSettings();
      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      this.isCalendarLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        this.getActivitiesWithLoad();
      } , 1500),
    );
  }

  createOnDateChange() {
    return reaction(
      () => this.date,
      () => {
        const isNeedToLoadNewEvents = this.checkAndSetDates();
        if(isNeedToLoadNewEvents){
          this.getActivitiesWithLoad();
        }
      },
    );
  }

  checkAndSetDates() {
    if (!this.date){
      return false;
    }

    const {
      start,
      end,
      monthNumber
    } = getMonthRangeWithIndents(this.date);
    const isDateOutOfRange = !isDateInRange(this.date, this.start, this.end);
    const isMonthChange = monthNumber !== this.month;

    if (isDateOutOfRange || isMonthChange) {
      this.start = start;
      this.end = end;
      this.month = monthNumber;
    }

    return isDateOutOfRange || isMonthChange;
  }

  * getActivitiesWithLoad() {
    this.isCalendarLoad = true;
    try {
      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      this.isCalendarLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  * getActivities() {
    this.isCalendarLoad = true;
    try {
      const start = async () => {
        const activitiesResp = await getCalendarList({
          start: this.start,
          end: this.end,
          ...getFilterParams(this.filters)
        });

        this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.calendar], {
          ...setWhereNameAndOrderNameFilterParams({
            whereFilters: this.filters,
            orderFilters: {}
          })
        });

        this.events = calendarNormalizer(
          activitiesResp.data.data,
          activitiesResp.data?.configData?.statusId
        );
      };

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: start,
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error }),
      });

    } catch (error) {
      console.log(error);
    }
  }

  * initPage() {
    this.pageActive = true;
    this.isCalendarLoad = true;
    try {
      const { defaultCalendarView } = this.coreStore.SettingsStore.settings;
      this.setCurrentView(defaultCalendarView);

      const today = new Date();
      const {
        start,
        end,
        monthNumber
      } = getMonthRangeWithIndents(today);
      this.start = start;
      this.end = end;
      this.month = monthNumber;

      this.setFiltersFromServer();
      yield this.getSettings();
      yield this.getActivities();
    } catch (error) {
      console.log(error);
    } finally {
      this.isCalendarLoad = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find(filter => (
      filter.url === URLS[MODULES_NAMES.calendar]
    ))?.value;

    this.onFiltersChange();
    if(serverFilterValue) {
      this.filters = {
        ...serverFilterValue.filters?.where
      };
    }
    this.onFiltersChange = this.createOnFiltersChangeReaction();
  }

  *getSettings() {
    this.isCalendarLoad = true;
    try {
      const filtersDataResp = yield getFilterOptions();

      const profileId = this.coreStore.SettingsStore.profile.id;
      this.filtersData = filterOptionsNormalizer(filtersDataResp.data.data, profileId);
    } catch (error) {
      console.log(error);
    } finally {
      this.isCalendarLoad = false;
    }
  }

  setShowMoreAnchorElem(elem){
    this.showMoreAnchorElem = elem;
  }

  setShowMoreEvents(events){
    this.showMoreEvents = events;
  }

  resetShowMoreData () {
    this.setShowMoreAnchorElem(null);
    this.setShowMoreEvents(null);
  }

  resetStore() {
    this.pageActive = false;
    this.isCalendarLoad = true;
    this.isFiltersOpen = false;
    this.events = [];
    this.filters = {};
    this.filtersData = {};
    this.date = null;
    this.userSelectedDate = null;


    this.onFiltersChange();
    this.resetFilters();
    this.onFiltersChange = this.createOnFiltersChangeReaction();
  }

  get selectedFiltersCount() {
    const countOfFilter = getFiltersCount(this.filters);
    const userSelectedDateCount  = this.userSelectedDate ? 1 : 0;
    return countOfFilter + userSelectedDateCount;
  }

  setFilters(newFilters) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }

  setUserSelectedDate(date) {
    this.userSelectedDate = date;
  }

  setDate(date) {
    this.date = date;
  }

  resetFilters() {
    this.filters = {};
  }

  onClear() {
    this.onFiltersChange();
    this.onDateChange();
    const isFiltersSelected = getFiltersCount(this.filters) > 0;

    if(isFiltersSelected){
      this.resetFilters();
    }
    if(this.userSelectedDate){
      this.setDate(new Date());
      this.setUserSelectedDate(null);
      const { defaultCalendarView } = this.coreStore.SettingsStore.settings;
      this.setCurrentView(defaultCalendarView);
    }

    this.onFiltersChange = this.createOnFiltersChangeReaction();
    this.onDateChange = this.createOnDateChange();

    const isNeedToLoadNewEvents = this.checkAndSetDates();

    if(isFiltersSelected || isNeedToLoadNewEvents){
      this.getActivitiesWithLoad();
    }
  }

  setCurrentView(view) {
    this.currentView = view || CALENDAR_VIEWS.month;
  }

  toggleFiltersIsOpen = () => {
    this.isFiltersOpen = !this.isFiltersOpen;
  };
}

export default CalendarStore;
