import ApiService from '@services/api/api.service';

import { ContactNote, DeleteNoteParams, GetNoteByIdParams, GetNotesByTypeParams } from '@/shared/types/note';

export const saveNote = (params: ContactNote) => {
  return ApiService.post('/api/note/update', params);
};

export const getNoteById = (params: GetNoteByIdParams) => {
  return ApiService.get('/api/note/getById', { params });
};

export const deleteNote = (params: DeleteNoteParams) => {
  return ApiService.delete('/api/note/delete', { params });
};

export const getNotesByType = (params: GetNotesByTypeParams) => {
  return ApiService.get('/api/note/getNotesByType', { params });
};
