export const SELECT_PROPS = {
  style: {
    fontSize: 12,
    width: '70px',
  }
};

export const MENU_LIST_PROPS = {
  style: {
    fontSize: 12,
    padding: 0,
  }
};

export const TEXT_FIELD_PROPS = {
  style: {
    padding: 0,
    flex: 1
  }
};

export const INPUT_STYLE_PROPS = {
  style: {
    padding: '3px 10px'
  }
};
