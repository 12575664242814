import { flow, IReactionDisposer, makeAutoObservable, reaction } from 'mobx';
import { debounce, isEmpty } from 'lodash';
import { Store } from '@store';

import CommonTableStore from '@services/store/commonTableStore';

import {
  deleteContacts,
  getContacts,
  updateContactFlags,
  updateContactRating,
} from '@/shared/services/api/contacts/contacts';
import saveContact from '@services/api/addContact/saveContact';

import { contactsNormalizer } from '@services/store/contactsStore/normalizers/contactsNormalizers';

import { NotificationHelper } from '@/shared/utils/NotificationHelper';
import { AsyncRequestExecutor } from '@/shared/utils/asyncRequestExecuter';
import { getDataForBookmark } from '@/shared/utils/getDataForBookmark';
import { getDeleteOrRestoreItemsParams } from '@/shared/utils/getDeleteOrRestoreItemsParams';
import {
  getMultipleSortParams,
  getFilterParams,
  getFiltersCount,
  getGlobalFlagged,
  setWhereNameAndOrderNameFilterParams,
} from '@/shared/utils/filterUtils';
import { filterItemsByPermission } from '@/shared/utils/filterItemsByPermission';

import { ContactsResponseType, ConvertedFiltersData, FiltersType } from '@services/store/contactsStore/types';
import { IdType, ItemWithId, MultipleSortingState } from '@/shared/types/commonTypes';
import { ContactItem, HouseHoldContactItem } from '@/shared/types/contact';

import { CONTACTS_TABLE_COLUMNS_IDS } from '@constants/contactsTableColumnsConfig';

import { CONTACT_TYPES, ENTITY_NAMES, SortDirectionNames } from '@constants/common';
import { NOTIFICATION_TYPES } from '@constants/notifications';
import { MODULES_NAMES, URLS } from '@constants/modulesURLs';


export class ContactsStore {
  asyncRequestExecutor: AsyncRequestExecutor;
  coreStore: Store;
  filters: FiltersType = {};
  filtersData: ConvertedFiltersData = {} as ConvertedFiltersData;
  isFetching: boolean = false;
  isPageActive: boolean = false;
  notificationHelper: NotificationHelper;
  onFiltersChangeReaction: IReactionDisposer;
  onLoadStartReaction: IReactionDisposer;
  table: CommonTableStore<ContactItem>;

  constructor(coreStore: Store) {
    makeAutoObservable(this, {
      asyncRequestExecutor: false,
      deleteContacts: flow,
      getContacts: flow,
      getContactsWithLoad: flow.bound,
      initPage: flow,
      saveContact: flow.bound,
      updateFlags: flow,
      updateRank: flow,
    });

    this.coreStore = coreStore;

    this.asyncRequestExecutor = new AsyncRequestExecutor();
    this.notificationHelper = new NotificationHelper(
      this.coreStore.NotificationsStore,
      ENTITY_NAMES.contact
    );

    this.table = new CommonTableStore<ContactItem>({
      checkboxItemsProcessor: filterItemsByPermission,
      onGlobalFlaggedChangeReactionCallback: this.getContactsWithLoad,
      onPageChangeReactionCallback: this.getContactsWithLoad,
      onSortReactionCallback: this.getContactsWithLoad,
    });


    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
    this.onLoadStartReaction = this.createOnLoadStartReaction();
  }

  *deleteContacts(arrayOfIds: Array<IdType>) {
    this.isFetching = true;

    try {
      const params = getDeleteOrRestoreItemsParams(arrayOfIds);

      const countOfEntities = arrayOfIds.length;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => deleteContacts(params),
        onSuccess: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.success,
          countOfEntities,
        }),
        onError: () => this.notificationHelper.remove({
          status: NOTIFICATION_TYPES.error,
          countOfEntities,
        })
      });

      yield this.getContactsWithLoad();

      this.table.checkAndSetIfPageOutOfRange();
      this.table.refreshSelectedIds(arrayOfIds);
    } catch (error) {
      console.error(error);
    } finally {
      this.isFetching = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *getContacts() {
    this.table.clearItems(true);
      
    const response: ContactsResponseType = yield getContacts({
      page: this.table.currentPage,
      ...getFilterParams(this.filters),
      ...getMultipleSortParams({
        ...this.table.multipleSorting,
      }),
      ...getGlobalFlagged(this.table.globalFlagged),
    });

    this.coreStore.SettingsStore.updateGlobalFilters(URLS[MODULES_NAMES.allContacts], {
      page: this.table.currentPage,
      ...setWhereNameAndOrderNameFilterParams({
        whereFilters: {
          ...this.filters,
          flagged: [Number(this.table.globalFlagged)]
        },
        orderFilters: this.table.multipleSorting
      }),
    });

    const currentUserId = this.coreStore?.SettingsStore?.profile?.id;
    const { filtersData, contacts, pagination } = contactsNormalizer(response, currentUserId);

    this.filtersData = filtersData;
    this.table.items = contacts;

    this.table.setPaginationData(pagination);
    this.table.checkAndSetIfPageOutOfRange();
  }

  *getContactsWithLoad() {
    if(!this.isPageActive){
      return;
    }

    try {
      this.isFetching = true;
      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: async () => this.getContacts(),
        onError: () => this.notificationHelper.load({ status: NOTIFICATION_TYPES.error })
      });

    } catch (error) {
      console.log(error);
    } finally {
      this.isFetching = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *initPage() {
    this.setFiltersFromServer();

    this.isPageActive = true;
    yield this.getContactsWithLoad();
  }

  setFiltersFromServer() {
    const serverFilterValue = this.coreStore.SettingsStore.globalFilters.find((filter: any) => (
      filter.url === URLS[MODULES_NAMES.allContacts]
    ))?.value;

    this.onFiltersChangeReaction();
    this.table.onPageChangeReaction();
    this.table.onGlobalFlaggedChangeReaction();
    this.table.onSortingChangeReaction();

    if(serverFilterValue) {
      this.table.setCurrentPage(Number(serverFilterValue.page) || 1);

      if(serverFilterValue.filters?.where) {
        const { officePrimaryAdvisor, classification, type, status, flagged } = serverFilterValue.filters.where;

        this.filters = {
          officePrimaryAdvisor: officePrimaryAdvisor,
          classification: classification,
          type: type,
          status: status,
        };
        this.table.setGlobalFlaggedFilters(Boolean(flagged?.[0]) || false);  
      }
      this.table.multipleSorting = serverFilterValue.filters?.order || {};
    } else {
      this.table.multipleSorting = isEmpty(this.table.multipleSorting) && {
        [CONTACTS_TABLE_COLUMNS_IDS.name]: SortDirectionNames.Asc
      };
    }

    this.table.onSortingChangeReaction = this.table.createOnSortingChangeReaction();
    this.table.onGlobalFlaggedChangeReaction = this.table.createOnGlobalFlaggedChangeReaction();
    this.table.onPageChangeReaction = this.table.createOnPageChangeReaction();
    this.onFiltersChangeReaction = this.createOnFiltersChangeReaction();
  }

  *saveContact(data: any) {
    try {
      this.isFetching = true;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => saveContact(data),
        continueOnError: false,
        onError: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.error }),
        onSuccess: () => this.notificationHelper.create({ status: NOTIFICATION_TYPES.success })
      });
      yield this.getContactsWithLoad();
    } catch (error) {
      console.log(error);
    } finally {
      this.isFetching = false;
      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  *updateFlags(arrayOfIds: Array<ItemWithId>, state: boolean){
    try {
      const params = getDataForBookmark(arrayOfIds, state);
      yield updateContactFlags(params);
      //TODO clarify BA about notification
      arrayOfIds.forEach((item) => {
        this.table.updateItemById(item.id, {
          flagged: Number(state)
        });
      });
      if(this.table.globalFlagged) {
        this.getContactsWithLoad();
      }
    } catch (error) {
      console.log(error);
    }
  }

  *updateRank(id: IdType, rating: number) {
    try {
      const isRankSortActive = !!this.table.multipleSorting?.[
        CONTACTS_TABLE_COLUMNS_IDS.rating as keyof MultipleSortingState
      ];

      if(isRankSortActive){
        this.isFetching = true;
        this.table.clearItems();
      }

      const householdContact = this.table.items.find((contact) => (
        contact.id === id && contact.type === CONTACT_TYPES.HOUSEHOLD
      )) as HouseHoldContactItem;

      yield this.asyncRequestExecutor.wrapAsyncOperation({
        func: () => updateContactRating({ id, rating }),
        onSuccess: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.success }),
        onError: () => this.notificationHelper.update({ status: NOTIFICATION_TYPES.error })
      });

      if(isRankSortActive || householdContact){
        const householderContacts = householdContact.householderContacts;
        this.table.items.forEach(contact => {
          if(contact.id === householderContacts[0].contactId || contact.id === householderContacts[1].contactId) {
            this.table.updateItemById(contact.id, { rating });
          }
        });
        this.table.updateItemById(id, { rating });
      } else {
        this.table.updateItemById(id, { rating });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isFetching = false;

      this.asyncRequestExecutor.executeFinallyCallbacksAndClear();
    }
  }

  createOnFiltersChangeReaction() {
    return reaction(
      () => this.filters,
      debounce(() => {
        if (this.isPageActive) {
          this.table.currentPage = 1;
          this.getContactsWithLoad();
        }
      }, 1500)
    );
  }

  createOnLoadStartReaction() {
    return reaction(
      () => this.isFetching,
      () => {
        if (this.isPageActive && this.isFetching) {
          this.coreStore.NotificationsStore.closeAll();
        }
      });
  }

  get selectedFiltersCount() {
    return getFiltersCount(this.filters);
  }

  resetFilters() {
    this.filters = {};
  }

  resetState() {
    this.isPageActive = false;
    this.resetFilters();
    this.table.resetTable();
  }
  setFilters(newFilters: any) {
    this.filters = {
      ...this.filters,
      ...newFilters
    };
  }
}
