import { createContext, useContext } from 'react';

import AccountsAndPlansStore from './accountsAndPlansStore';
import AppStore from './appStore';
import AppHeaderStore from './appHeaderStore';
import AuthStore from './authStore';
import CalendarStore from './calendarStore';
import ContactCustomFields from './contactCustomFields';
import ContactDetailsAccountAndPlanStore from './contactDetailsAccountAndPlanStore';
import ContactDetailsActivitiesStore from './contactDetailsActivitiesStore';
import ContactDetailsOverview from './contactDetailsOverview';
import ContactDetailsProfileStore from './contactDetailsProfileStore';
import ContactDetailsStore from './contactDetailsStore';
import ContactDetailsTodosStore from './contactDetailsTodosStore';
import ContactsStore from './contactsStore';
import DashboardStore from './dashboardStore';
import EmployeeStore from './employeeStore';
import ListsStore from './listsStore';
import ModalStore from './modalStore';
import NotificationsStore from './notificationsStore';
import RelatedContactsStore from './relatedContactsStore';
import RouterStore from './routerStore';
import SalesCycleStore from './salesCycle';
import SessionStore from './sessionStore';
import SettingsStore from './settingsStore';
import ToDosStore from './todosStore';
import TrashStore from './trashStore';
import UserSettingsContactCustomFields from './userSetttingsContactCustomFields';
import UserSettingsManageTagsStore from './userSettingsManageTagsStore';
import UserSettingsStore from './userSettingsStore';
import UserSettingsUserCustomFieldsStore from './userSettingsUserCustomFieldsStore';


export class Store {
  AccountsAndPlansStore: AccountsAndPlansStore = new AccountsAndPlansStore(this);
  AppStore: AppStore;
  AppHeaderStore: AppHeaderStore;
  AuthStore: AuthStore;
  CalendarStore: CalendarStore;
  ContactCustomFields: ContactCustomFields;
  ContactDetailsAccountAndPlanStore: ContactDetailsAccountAndPlanStore;
  ContactDetailsActivitiesStore: ContactDetailsActivitiesStore;
  ContactDetailsOverview: ContactDetailsOverview;
  ContactDetailsProfileStore: ContactDetailsProfileStore;
  ContactDetailsStore: ContactDetailsStore;
  ContactDetailsTodosStore: ContactDetailsTodosStore;
  ContactsStore: ContactsStore;
  DashboardStore: DashboardStore;
  EmployeeStore: EmployeeStore;
  ListsStore: ListsStore;
  ModalStore: ModalStore;
  NotificationsStore: NotificationsStore;
  RelatedContactsStore: RelatedContactsStore;
  RouterStore: RouterStore;
  SalesCycleStore: SalesCycleStore;
  SessionStore: SessionStore;
  SettingsStore: SettingsStore;
  ToDosStore: ToDosStore;
  TrashStore: TrashStore;
  UserSettingsContactCustomFields: UserSettingsContactCustomFields;
  UserSettingsManageTagsStore: UserSettingsManageTagsStore;
  UserSettingsStore: UserSettingsStore;
  UserSettingsUserCustomFieldsStore: UserSettingsUserCustomFieldsStore;

  constructor() {
    // NOTE ! it is important to init utility stores at first
    this.ModalStore = new ModalStore();
    this.NotificationsStore = new NotificationsStore();
    this.SettingsStore = new SettingsStore(this);

    this.AccountsAndPlansStore = new AccountsAndPlansStore(this);
    this.AppStore = new AppStore(this);
    this.AppHeaderStore = new AppHeaderStore(this);
    this.AuthStore = new AuthStore(this);
    this.CalendarStore = new CalendarStore(this);
    this.ContactCustomFields = new ContactCustomFields(this);
    this.ContactDetailsAccountAndPlanStore = new ContactDetailsAccountAndPlanStore(this);
    this.ContactDetailsActivitiesStore = new ContactDetailsActivitiesStore(this);
    this.ContactDetailsOverview = new ContactDetailsOverview(this);
    this.ContactDetailsProfileStore = new ContactDetailsProfileStore(this);
    this.ContactDetailsStore = new ContactDetailsStore(this);
    this.ContactDetailsTodosStore = new ContactDetailsTodosStore(this);
    this.ContactsStore = new ContactsStore(this);
    this.DashboardStore = new DashboardStore(this);
    this.EmployeeStore = new EmployeeStore(this);
    this.ListsStore = new ListsStore(this);
    this.RelatedContactsStore = new RelatedContactsStore(this);
    this.RouterStore = new RouterStore(this);
    this.SalesCycleStore = new SalesCycleStore(this);
    this.SessionStore = new SessionStore(this);
    this.ToDosStore = new ToDosStore(this);
    this.TrashStore = new TrashStore(this);
    this.UserSettingsStore = new UserSettingsStore(this);
    this.UserSettingsContactCustomFields = new UserSettingsContactCustomFields(this);
    this.UserSettingsUserCustomFieldsStore = new UserSettingsUserCustomFieldsStore(this);
    this.UserSettingsManageTagsStore = new UserSettingsManageTagsStore(this);
  }
}

export const store = new Store();

export const StoreContext = createContext(store);

export const useStore = () => useContext(StoreContext);
