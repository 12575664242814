import styled from 'styled-components';

import { COLORS } from '@constants/colors';

export const TextWrapper = styled.div`
  padding: 5px 0 10px 5px;
  font-size: 14px;
  color: ${COLORS.helpText};
`;

export const UserItemWrapper = styled.div`
  cursor: pointer;
`;