import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useToggle } from '@/shared/customHooks';

import { SEARCH_BUTTON_MODE } from './data';

import { SearchPanel, GeneralRowFilter } from './components';
import { Search } from '@/assets';

import { FilterWrapper, RowFilterWrapper, SearchIconButton } from './styles';

import { GeneralFiltersProps, GeneralFiltersValue, IconButtonModeType } from './types';


export const GeneralFilters = ({
  instanceStore,
  numberOfCurrentStateNotes = 0,
  numberOfNotesToRender = 0,
  onChange,
}: GeneralFiltersProps) => {

  const { setFilters, filters, isOpenSearchPanel, setIsOpenSearchPanel } = instanceStore;

  const [ isVisibleCloseIconButton, setIsVisibleCloseIconButton ] = useToggle(false);
  const [ searchIconButtonMode, setSearchIconButtonMode ] = useState<IconButtonModeType>(SEARCH_BUTTON_MODE.inactive);
  
  const { control, reset } = useForm<GeneralFiltersValue>({
    defaultValues: filters
  });
  
  const onSearchIconClick = useCallback(() => {
    setIsOpenSearchPanel(!isOpenSearchPanel);
    setSearchIconButtonMode(() => {
      if(numberOfCurrentStateNotes > numberOfNotesToRender && isVisibleCloseIconButton) {
        return SEARCH_BUTTON_MODE.withResult;
      }
      return isOpenSearchPanel ? SEARCH_BUTTON_MODE.inactive : SEARCH_BUTTON_MODE.active;
    });
  }, [isOpenSearchPanel, numberOfCurrentStateNotes, numberOfNotesToRender, isVisibleCloseIconButton]);


  return(
    <FilterWrapper>
      <RowFilterWrapper>
        <SearchIconButton
          variant={ 'outlined' }
          onClick={ onSearchIconClick }
          mode={ searchIconButtonMode }
        >
          <Search/>
        </SearchIconButton>
        <GeneralRowFilter
          filters={ filters }
          onChange={ onChange }
          setFilters={ setFilters }
        />
      </RowFilterWrapper>
      { isOpenSearchPanel &&
        <SearchPanel
          control={ control }
          filters={ filters }
          isVisibleCloseIconButton={ isVisibleCloseIconButton }
          onChange={ onChange }
          reset={ reset }
          setFilters={ setFilters }
          setIsVisibleCloseIconButton={ setIsVisibleCloseIconButton }
          setSearchIconButtonMode={ setSearchIconButtonMode }
        />
      }
    </FilterWrapper>
  );
};