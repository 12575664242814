import React, { ChangeEvent, memo, useCallback, useMemo } from 'react';

import { HouseholdMember, Check } from '@/assets';

import DotsMenu from '@components/DotsMenu';
import LinkedContactCell from'@modules/LinkedContactCell';
import Rank from '@components/Rank';
import StatusChip, { STATUS_TEMPLATES } from '@components/StatusChip';
import Table from '@components/Table';
import TextWithTooltip from '@components/TextWithTooltip';
import Tooltip from '@components/Tooltip/Tooltip';
import { OutOfSightTD } from '@components/OutOfSight';

import { HouseHoldIconWrapper, StatusChipWrapper } from './styles';

import { LIST_CONTACTS_DOTS_MENU_TYPES, getDotsMenuOptions } from './data';

import { ListContactsDotsMenuItemTypes, ListContactsTableRowProps } from './types';

export const ListContactsTableRow = memo(({
  data,
  isCheckboxActive,
  isContactManagement,
  listType,
  moveToContactOverview,
  onCheckboxClick,
  onDelete,
  onLimitedClick,
  onRatingChangeCb,
  onRemove,
  onRowBookmarkCb,
}: ListContactsTableRowProps) => {

  const isContactInList = Boolean(data?.contactInList);
  const isBlocked =  typeof data?.editAllow === 'number' && !data?.editAllow;

  const onCheckboxClickCb = useCallback(() => (
    onCheckboxClick(data)
  ),[onCheckboxClick, data]);

  const onBookmarkClick = useCallback(() => {
    onRowBookmarkCb(data.id);
  }, [data.id, onRowBookmarkCb]);

  const onRatingClick = useCallback((newRating: number) => {
    onRatingChangeCb(data.id, newRating);
  }, [data.id, onRatingChangeCb]);

  const moveToContactOverviewWrapper = useCallback(() => {
    moveToContactOverview(data.id, data.type);
  }, [data, moveToContactOverview]);

  const menuOptions = useMemo(() => (
    getDotsMenuOptions(listType, isContactManagement)
  ),[isContactManagement, listType]);

  const onMenuSelect = useCallback((action: ListContactsDotsMenuItemTypes) => {
    if(action === LIST_CONTACTS_DOTS_MENU_TYPES.view){
      moveToContactOverviewWrapper();
    }

    if(action === LIST_CONTACTS_DOTS_MENU_TYPES.delete){
      onDelete(data.id);
    }

    if(action === LIST_CONTACTS_DOTS_MENU_TYPES.remove){
      onRemove(data.id);
    }
  }, [data.id, moveToContactOverviewWrapper, onDelete, onRemove]);

  return (
    <Table.Row
      $preventPointerEvents={ isBlocked }
      hover
      onDoubleClick={ moveToContactOverviewWrapper }
      role="checkbox"
      tabIndex={ -1 }
    >
      <Table.CellWithControls
        bookmarkIconProps={ {
          isActive: Boolean(data?.flagged),
          onStateChange: onBookmarkClick
        } }
        checkboxProps={ {
          checked: isCheckboxActive,
          onChange: onCheckboxClickCb
        } }
      />
      <LinkedContactCell contact={ data }/>
      <Table.Cell>
        { data.type }
        {
          Boolean(data?.contactInHousehold) &&
          <HouseHoldIconWrapper>
            <Tooltip
              title={ 'Household Member' }
            >
              <HouseholdMember/>
            </Tooltip>
          </HouseHoldIconWrapper>
        }
      </Table.Cell>
      <Table.Cell>
        <Rank
          initRank={ data.rating }
          onRankChange={ onRatingClick }
          resettable
        />
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ data?.primaryEmail || '-' }/>
      </Table.Cell>
      <Table.Cell>
        <TextWithTooltip text={ data?.primaryPhone || '-' }/>
        {
          isContactInList &&
          <StatusChipWrapper>
            <StatusChip
              $bgColor={ STATUS_TEMPLATES.completed.colorScheme.bgColor }
              $color={ STATUS_TEMPLATES.completed.colorScheme.color }
              icon={ <Check /> }
              label={ 'Added' }
            />
          </StatusChipWrapper>
        }
        <DotsMenu<ListContactsDotsMenuItemTypes>
          $marginLeft={ isContactInList ? '20px' : 'auto' }
          $marginRight={ '20px' }
          items={ menuOptions }
          onSelect={ onMenuSelect }
        />
      </Table.Cell>
      {
        isBlocked &&
          <OutOfSightTD onClick={ onLimitedClick } />
      }
    </Table.Row>
  );
});

ListContactsTableRow.displayName = 'ListContactsTableRow';
