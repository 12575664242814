import { isEmpty } from 'lodash';
import {
  differenceInMonths,
  endOfQuarter,
  endOfYear,
  format,
  getQuarter,
  getYear,
  setYear,
  startOfQuarter,
  startOfYear,
} from 'date-fns';

import { PERIOD_FILTER_NAMES, QUARTERS_SCHEME } from '@constants/dashboard';
import { YEAR_MONTH_DAY } from '@constants/dateFormats';

import { ActivityTaskResponseData, AdvisorFilter, Dataset, Period } from '@/shared/types/dashboard';
import { IdType } from '@/shared/types/commonTypes';
import { getSortedUserOption } from '@/shared/utils/filterUtils';

export const getSummary = (data: Array<Dataset>): number => {
  if(!isEmpty(data)) {
    return data.reduce((acc: number, current: Dataset) => acc + Number(current.dataBalance.value), 0);
  }
  return 0;
};

export const getPeriodFilters = (period: Period, year: Date, quarter: number): {
  startDate: string,
  endDate: string
} => {
  let startDate = startOfYear(year);
  let endDate = endOfYear(year);

  if(period === PERIOD_FILTER_NAMES.quarter) {
    startDate = startOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].firstMonthNumber));
    endDate = endOfQuarter(new Date(getYear(year), QUARTERS_SCHEME[quarter].endMonthNumber));
  }

  return {
    startDate: format(startDate, YEAR_MONTH_DAY),
    endDate: format(endDate, YEAR_MONTH_DAY),
  };
};

export const dashboardDataNormalizer = (data: ActivityTaskResponseData, currentUserId: IdType) => ({
  activityCount: data.activityCount,
  taskCount: data.taskCount,
  primaryAdvisers: getSortedUserOption(data.primaryAdvisers, currentUserId)
});

export const getAdviserItemsParams = (data?: Array<IdType>) => {
  if(Array.isArray(data)) {
    return data.reduce((acc: AdvisorFilter, id: IdType, index: number) => {
      acc[`adviserId[${index}]`] = id;
      return acc;
    }, {});
  } else {
    return {
      'adviserId[0]': data,
    };
  }
};

export const getPeriodYearQuarter = (startDateString: string, endDateString: string): {
  period: Period,
  year: Date,
  quarter: number
} => {
  let period: Period = PERIOD_FILTER_NAMES.year;
  let year = new Date();
  let quarter = getQuarter(new Date());

  if(startDateString && endDateString) {
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const differenceBetweenDates = differenceInMonths(endDate, startDate);
  
    const actualYear = getYear(startDate);
    year = setYear(startDate, actualYear);

    if(differenceBetweenDates === 11) {
      period = PERIOD_FILTER_NAMES.year;
    } else {
      period = PERIOD_FILTER_NAMES.quarter;
      quarter = getQuarter(startDate);
    }  
  }

  return {
    period,
    year,
    quarter
  };
};
