import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStore } from '@store';

import NotesAndHistory, { NOTES_TAB_VALUE, NotesTasksTable } from '@modules/NotesAndHistory';

import { useSalesActivePopupStore } from '@modules/SalesPipline/SalesActivePopup/store';

import {
  SALES_ACTIVE_CYCLE_FIELD_NAMES,
  SALES_ACTIVE_NOTES_MEMBERS_FIELD_NAMES,
  SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES
} from '@constants/salesActiveCycle';

import { TasksAndNotesProps } from './types';

const INPUT_NAMES = {
  tagId: SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES.id,
  tagValue: SALES_ACTIVE_NOTES_TAGS_FIELD_NAMES.value,
  notes: SALES_ACTIVE_CYCLE_FIELD_NAMES.notes,
  ...SALES_ACTIVE_NOTES_MEMBERS_FIELD_NAMES
};

const tabs = [
  {
    value: NOTES_TAB_VALUE,
    label: 'SC Notes & History'
  },
  {
    value: 'tasks',
    label: 'SC Linked Tasks'
  }
];


export const TasksAndNotes = observer(({
  onEditToDo
}: TasksAndNotesProps) => {
  const { control } = useFormContext();

  const { profile } = useStore().SettingsStore;

  const {
    linkedContactData,
    notesAndHistoryStore,
    notesUtilsStore,
    predefinedTags,
    setTodoTableActivePage,
    table,
  } = useSalesActivePopupStore();

  const components = useMemo(() => {
    return {
      tasks: () => (
        <NotesTasksTable
          $height={ '1px' }
          onScrollToBottomAction={ setTodoTableActivePage }
          onTaskEdit={ onEditToDo }
          tasks={ table.items || [] }
        />
      )
    };
  },[table.items]);

  return (
    <NotesAndHistory
      $maxHeight={ '765px' }
      components={ components }
      control={ control }
      currentUser={ profile }
      deleteConfirmationText={ `The Note will be deleted if you save the changes for the Sales Cycle. 
        Are you sure you want to delete note?`
      }
      disableAddNoteTooltipText={ 'To create a Note you need to select Linked Contact' }
      instanceStore={ notesAndHistoryStore }
      isAddNoteBtnDisabled={ !linkedContactData }
      linkedContactData={ linkedContactData }
      names={ INPUT_NAMES }
      noteContactOptions={ notesUtilsStore.noteContactOptions }
      onAddEditEnd={ notesUtilsStore.toggleNotesPanelStateOff }
      onAddEditStart={ notesUtilsStore.toggleNotesPanelStateOn }
      predefinedTags={ predefinedTags }
      tabs={ tabs }
    />
  );
});
