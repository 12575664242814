import { Paper } from '@material-ui/core';
import styled from 'styled-components';

import { COLORS } from '@constants/colors';

import { DividerProps } from './types';

export const DatePickersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 250px;

  & > div {
    padding: 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  /* in order to stretch the block to the full width without padding */
  width: calc(100% + 40px);
  height: 60px;
  margin: 0px -20px;
  padding: 0px 20px;
  border: 1px solid ${COLORS.dividingLines};
  border-right: none;
  border-left: none;
  color: ${COLORS.grey};
  background: ${COLORS.light};
  box-sizing: border-box;
`;

export const StyledPaper = styled(Paper)`
  display: flex;
  align-items: center;
  flex: 1;
  height: 30px;
  border-radius: 4px;
  border: 1px solid ${COLORS.borders};
  box-shadow: none;
  box-sizing: border-box;

  & fieldset {
    border: none;
  }

  & .MuiSelect-select {
    height: 28px !important;
    padding-right: 6px;
    color: ${COLORS.primaryText};
  }

  & > div {
    width: auto;
    padding: 0;
  }
`;

export const Divider = styled.div<DividerProps>`
  min-width: 1px;
  height: ${({ $height }) => $height && $height};
  background: ${COLORS.borders};
`;

export const CloseIcon = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 10px;

  &:before {
    position: absolute;
    bottom: 33px;
    right: 53px;
    content: '';
    width: 1px;
    height: 24px;
    background-color: ${COLORS.borders};
  }
`;
