import React, { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useForm, useWatch } from 'react-hook-form';

import AttentionBlock from '@components/AttentionBlock';
import FormBlock from '@components/FormBlock';
import HFHiddenInput from '@modules/HookFormComponents/HFHiddenInput';
import HFSelect from '@modules/HookFormComponents/HFSelect';
import ListOwner from '@modules/Lists/ListOwner';
import ListDescription from '@modules/Lists/ListDescription';
import ListName from '@modules/Lists/ListName';
import SplitToggle from '@modules/Lists/SplitToggle';
import Modal from '@components/Modal';
import PopupActionsButtons from '@components/PopupActionsButtons';

import { LeftWrapper, RightWrapper } from './styles';

import { ERROR_SPACE_VALUE } from '@constants/style';
import {
  DYNAMIC_ATTENTIONS_TEXT,
  LIST_SETUP_FIELDS_NAMES,
  LIST_SETUP_TYPE_OPTIONS,
  LISTS_TYPES,
  MANUAL_ATTENTIONS_TEXT,
  TYPE_FIELDS,
} from '@constants/lists';

import { getDefaultState, LEFT_LABEL_WIDTH, RIGHT_LABEL_WIDTH, } from './data';

import { AddNewListPopupProps } from './types';
import { SaveListData } from '@/shared/types/lists';

export const AddNewListPopup = ({
  closeModal,
  modalProps,
}: AddNewListPopupProps) => {
  const {
    currentUser,
    parentId,
    saveCallback,
  } = modalProps;

  const [ isGlobalDisabed, setIsGlobalDisabled ] = useState(false);

  const { control, handleSubmit, setError } = useForm<SaveListData>({
    mode: 'onSubmit',
    defaultValues: getDefaultState({
      parentId,
      listOwner: currentUser.id
    })
  });

  const listType = useWatch({
    control,
    name: LIST_SETUP_FIELDS_NAMES.typeList
  });

  const todayDateString = useMemo(() => format(new Date(), 'LL/dd/yyyy'), []);

  const setNameError = useCallback((errorText: string) => {
    setError(LIST_SETUP_FIELDS_NAMES.name, {
      type: 'custom',
      message: errorText
    });
  }, [setError]);

  const onSaveClick = useCallback(() => {
    handleSubmit((data) => {
      setIsGlobalDisabled(true);
      saveCallback(
        {
          ...data,
          filter:  data.typeList === LISTS_TYPES.Manual ? null : []
        } as SaveListData,
        setNameError
      );
    })();
  }, [handleSubmit, saveCallback, setNameError]);


  return (
    <Modal
      onClose={ closeModal }
      title={ 'New Custom List' }
    >
      <Modal.Content
        padding={ '30px 30px 15px 30px' }
        width={ '800px' }
      >
        <HFHiddenInput
          control={ control }
          defaultValue={ parentId }
          name={ LIST_SETUP_FIELDS_NAMES.parentId }
        />
        <HFHiddenInput
          control={ control }
          defaultValue={ TYPE_FIELDS.List }
          name={ LIST_SETUP_FIELDS_NAMES.typeList }
        />
        <FormBlock isBorderBottom={ false }>
          <FormBlock.Header title={ 'List Details' }/>
          <FormBlock.RowWrapper>
            <LeftWrapper>
              <FormBlock.FormField
                $labelWidth={ LEFT_LABEL_WIDTH }
                label={ 'List Type' }
              >
                <HFSelect
                  control={ control }
                  name={ LIST_SETUP_FIELDS_NAMES.typeList }
                  options={ LIST_SETUP_TYPE_OPTIONS }
                />
              </FormBlock.FormField>
            </LeftWrapper>
            <RightWrapper>
              <FormBlock.FormField
                $labelWidth={ RIGHT_LABEL_WIDTH }
                label={ 'List Creation Date' }
              >
                <FormBlock.VerticalCenteredWrapper>
                  {todayDateString}
                </FormBlock.VerticalCenteredWrapper>
              </FormBlock.FormField>
            </RightWrapper>
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper marginBottom={ '10px' }>
            <LeftWrapper>
              <ListOwner
                control={ control }
                labelWidth={ LEFT_LABEL_WIDTH }
                name={ LIST_SETUP_FIELDS_NAMES.listOwner }
                user={ currentUser }
              />
            </LeftWrapper>
            <RightWrapper>
              <SplitToggle
                control={ control }
                labelWidth={ RIGHT_LABEL_WIDTH }
                name={ LIST_SETUP_FIELDS_NAMES.splitHousehold }
              />
            </RightWrapper>
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper marginBottom={ `${ERROR_SPACE_VALUE}px` }>
            <AttentionBlock
              $lineHeight={ '21px' }
              $padding={ '12px 55px 12px 16px' }
              text={ listType === LISTS_TYPES.Dynamic ?  DYNAMIC_ATTENTIONS_TEXT : MANUAL_ATTENTIONS_TEXT }
            />
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper>
            <ListName
              control={ control }
              labelWidth={ LEFT_LABEL_WIDTH }
              name={ LIST_SETUP_FIELDS_NAMES.name }
            />
          </FormBlock.RowWrapper>
          <FormBlock.RowWrapper>
            <ListDescription
              control={ control }
              labelWidth={ LEFT_LABEL_WIDTH }
              name={ LIST_SETUP_FIELDS_NAMES.description }
            />
          </FormBlock.RowWrapper>
        </FormBlock>
      </Modal.Content>
      <Modal.Footer>
        <PopupActionsButtons
          disabled={ isGlobalDisabed }
          onCancelClick={ closeModal }
          onSaveClick={ onSaveClick }
          wrapperProps={ {
            marginLeft: 'auto',
          } }
        />
      </Modal.Footer>
    </Modal>
  );
};
